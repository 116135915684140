.bgAppNavbar{
  text-decoration: none;
  box-shadow: 0 1px 12px 0 rgba(5,3,0,.8);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 5px;
   padding-left: 0px;
}

.dropdown-item{
  text-decoration: none;
  color: #ccc;
}
.dropdown-item:hover{
  cursor: pointer;
  color: white;
  text-decoration: underline;
}

#context-dropdown::after{
  display: none;
}
.navMaxWidth{
  max-width: 100vw;
}

.logoAppBarLogin{
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

@media (max-width: 800px) {
  .logoAppBarLogin{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 15px;
  }
}

.logoAppBar{
  width: 170px;
}

.smallPatientName{
  list-style: none;
  padding-left: 0px !important;
}

.patientName{
}

.patientSmallName{
  line-height: 13px;
}

.truncate {
  width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar{
  padding: 0;
  padding-left: 10px;
  height: 85px;
}


@media (max-width: 800px) {
  .patientName{
  }
  .logoAppBar{
    width: 100px;
  }
}
@media (max-width: 700px) {
  .navbar-brand{
   margin-right: 0px !important;
    width: 20%;
  }
  .navbar{
    padding: 0;
    padding-left: 10px;
    height: 60px;
  }
}

.iconHomeSize{
}

.nav{
  padding:0;
}

.navbar-brand {
  display: inline-block;
  margin-right: 1rem;
   line-height: inherit;
  white-space: nowrap;
}
.headerHospital {
  text-align: center;
  vertical-align: middle;
  .headerHospitalName {
    font-weight: bold;
  }
  .headerHospitalDepartment{
    margin: auto 0;
  }
}

@media (max-width: 700px) {
  .headerHospital {
    text-align: center;
    vertical-align: middle;
    .headerHospitalName {
      font-weight: bold;
      margin-left: 5px;
    }
    .headerHospitalDepartment{
      margin: auto 0;
    }
  }
}

.openSide:hover{
  cursor: pointer;
}

.rightMenuItem{
}
.rightMenuItem:hover{
  cursor: pointer;
}
.appNavItem{
  //margin-top: 12px;
}