#switchDm{
  padding: 0.25rem 0rem;
}
div #switchDm{
  display: flex;
  align-items: center ;

  .toggle-btn {
    position: relative;
    top: 0%;
    width: 55px;
    height: 22px;
    border-radius: 40px;
  }

  input[type="checkbox"] {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  #_1st-toggle-btn span {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    opacity: 1;
    background-color: #fff;
    border-radius: 40px;
    transition: 0.2s ease background-color, 0.2s ease opacity;
  }

  #_1st-toggle-btn span:before,
  #_1st-toggle-btn span:after {
    content: "";
    position: absolute;
    top: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.5s ease transform, 0.2s ease background-color;
  }
  #_1st-toggle-btn span:before {
    background-color: #fff;
    transform: translate(-25px, 0px);
    z-index: 1;
  }

  #_1st-toggle-btn span:after {
    background-color: rgba(60,64,67,1);
    transform: translate(1px, 0px);
    z-index: 0;
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span {
    background-color: rgba(60,64,67,1);;
  }

  #_1st-toggle-btn input[type="checkbox"]:active + span {
    opacity: 0.5;
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span:before {
    background-color: rgba(60,64,67,1);;
    transform: translate(0px, -4px);
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span:after {
    background-color: #c3c3c3;
    transform: translate(35px, 0px);
  }

  /* First toggle btn completed */
}
