$light-grey: #F4F4F4;
// Variables
$white: #FFF;
$opac-white: rgba(255,255,255,0.7);
$blue: #343D4A;
$primary-color: $blue;
$dark-blue: #222732;
$grey: #888889;
$violet: #7B569F;
$alert: #F39200;
$danger: #F39200;

$menu-font: 'Roboto Condensed', sans-serif;
$main-font: 'Roboto', sans-serif;
$title-font: 'Roboto Slab', serif;

$nav-height: 80px;
$p-size: 18px;
$container-size: 1140px;
$radius: 3px;

// breakpoints
$landscape: 1024px;
$portrait: 768px;
$smartphone: 599px;
$small-smartphone: 480px;

.contactIco{
	font-size:24px;
	color:#7b569f;
}

.flsIcoColor{
	color: #7B569F;
}

.quote{
	font-size:1.5em;
	height:150px;
}

.pdiv{
	font-size: 18px;
	line-height: 1.6em;
	font-family: $main-font;
	font-weight: 300;
	letter-spacing: 0.35px;
}

.CookieConsent div{
	width: 40%;
	display: inline;
}

	.corporate{
@mixin font-regular {
	font-family: $main-font;
	font-weight: 300;
}

@mixin font-menu {
	font-family: $menu-font;
	font-weight: 300;
}

@mixin font-bold {
	font-family: $main-font;
	font-weight: 700;
}

@mixin font-title {
	font-family: $title-font;
	font-weight: 400;
}

@mixin lineBefore {
	content: '';
	display: block;
	height: 2px;
	right: 0;
	left: 0;
	top: 0;
	background-color: $primary-color;
}

@mixin transition {
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

@mixin gradient {
  	background: rgb(123,86,159);
	background: linear-gradient(120deg, rgba(123,86,159,0) 0%, rgba(123,86,159,0.25) 20%, rgba(34,39,50,0.6) 30%, $dark-blue 70%, $dark-blue 100%);
}

@mixin gradientBefore {
	content: '';
	display: block;
	height: 500px;
	width: 500px;
	position: absolute;
  	background-image:
    radial-gradient(
      	circle at top right,
      	rgba(123,86,159,0.3),
    	rgba(123,86,159,0) 70%
    );
}

.radial-violet--before {
	&::before {
		content: "";
		display: block;
		height: 1000px;
		width: 80%;
		max-width: 650px;
		position: absolute;
		background-image:
	    radial-gradient(
	      	circle at center left,
	      	rgba(123,86,159,0.45),
	    	rgba(123,86,159,0) 60%
	    );
	}
}

.mobile-only {
	display: none;
}

.shadowed {
	box-shadow: 0 0 60px rgba(0,0,0,0.05);
}

.centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.gradient-before {
	&::before {
		@include gradientBefore;
		right: 0;
		top: 0;
		z-index: 2;
	}
}

.blue {
	color: $blue;
	&--bg {
		color: $white;
		background-color: $blue;
	}
}

.grey {
	&--bg {
		background-color: $light-grey;
	}
}

.gradient {
	&--bg {
		@include gradient;
	}
}

.gradient-violet {
	&--bg {
		background-image:
	    radial-gradient(
	      	circle at top left,
	      	lighten($violet,20),
	      	 darken($violet,2) 50%
	    );
	}
}

.white {
	color: $white;
	span, 
	small,
	p, strong {
		color: $white;
	}
	&--bg {
		background-color: $white;
	}
}

* {
	@include font-regular;
	letter-spacing: 0.35px;
}

a {
	@include font-menu;
	text-decoration: none;
	&:hover {
		text-decoration: none!important;
		color: inherit;
	}
}

strong {
	font-weight: 700;
}

p + a,
p + ul, 
ul + a {
	margin-top: 30px;
}

// buttons

.btnS {
	padding: 10px 20px;
	color: $primary-color;
	&:hover {
		color: inherit;
	}
}

.btn-primary {
	@extend .btnS;
	@include font-menu;
	display: inline-block;
	color: $white;
	text-transform: uppercase;
	background-color: $violet;
	border-radius: $radius;
	cursor: pointer;
	@include transition;
	i {
		color: $white;
		margin: 0;
		padding: 0 10px 0 0;
		@include transition;
	}
	&:hover,
	&:active,
	&.active, 
	&:focus {
		color: $white;
		background-color: darken($violet, 5);
		i {
			padding: 0 5px;
		}
	}
}

.btn-secondary {
	@extend .btn-primary;
	background-color: darken($violet, 20);
	&:hover,
	&:active,
	&:focus {
		background-color: darken($violet, 30);
	}
}

.btn-menu {
	position: absolute;
	line-height: 75px;
    right: 10px;
    top: 10px;
    height: 75px;
    width: 50px;
    text-align: center;
    i {
    	color: $violet;
    	font-size: 20px;
    }
    &.close-menu {
    	top: 0;
    	right: 0;
    	height: 50px;
    	line-height: 50px;
    	i {
    		font-size: 16px;
    		color: $white;
    	}
    }
}

.btn-small {
	font-size: $p-size - 4;
	padding: 5px;
	color: $white;
	opacity: 0.5;
}

.btn-link {
	padding: 5px 5px;
	@extend .btnS;
	&:hover {
		color: $violet;
	}
}

.btn-link.white {
	color: $white;
	opacity: 0.7;
	@include font-menu;
	@include transition;
	&:hover {
		opacity: 1;
	}
	i {
		color: $white;
		margin: 0;
		padding: 0 5px;
		@include transition;
	}
}

.btn-icon {
	i {
		@include transition;
	}
	&:hover {
		i {
			color: $white;
		}
	}
}

// Content 
.max-width-sm {
	width: 100%;
	max-width: $container-size - 740;
}

.max-width-md {
	width: 100%;
	max-width: $container-size - 600;
}

.max-width-lg {
	width: 100%;
	max-width: $container-size - 450;
}

.max-width-xl {
	width: 100%;
	max-width: $container-size - 300;
}

// typography 
h1, .h1 {
	font-size: $p-size + 26;
	line-height: 1em;
	@include font-title;
}

h2, .h2 {
	font-size: $p-size + 18;
	line-height: 1em;
	@include font-title;
}

h3, .h3 {
	font-size: $p-size + 12;
}

h4, .h4 {
	@include font-title;
	font-size: $p-size + 6;
}

.condensed {
	font-family: $menu-font;
}

p {
	font-size: $p-size;
	line-height: 1.6em;
}

small {
	font-size: 0.6em;
}

.title {
	@include font-title;
	padding: 5px 0 30px;
	span {
		@include font-title;
		display: block;	}
	small {
		@include font-title;
		font-weight: 300;
		display: block;
		opacity: 0.6;
	}
}

.big-title {
	font-size: $p-size + 35;
}

.custom-list {
	padding: 0;
	li {
		list-style: none;
		position: relative;
		padding: 0 0 5px 30px;
		&::before {
			position: absolute;
			display: block;
			content: "\f00c";
			font-family: FontAwesome;
			left: 0;
			color: $violet;
		}
	}
}

.gradient-text {
	background: -webkit-linear-gradient(60deg, darken($violet,10), lighten($violet,20));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.motif {
	&--bg {
		background-repeat: no-repeat;
		background-position: right -200px top -100px;
		background-size: 500px auto;
		background-image: url('/Components/Corporate/assets/images/bone-monitor-opacity.svg');
	}
}

body {
	background-color: $light-grey;
	display: none;
	&.active {
		overflow: hidden;
	}
} 

// Nav
header {
	background-color: $white;
}

nav {
	padding: 10px 0 15px;
	.logo-wrapper {
		img {
			max-height: 80px;
		}
	}
	&.main-nav {
		ul {
			&.menu-list {
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: right;
				li {
					display: inline-block;
					a {
						display: block;
						position: relative;
						margin: 0 25px;
						@include transition;
						&::before {
							position: absolute;
							@include lineBefore;
							@include transition;
							right: 100%;
							height: 2px;
						}
						&.active {
							&::before {
								right: 0;
							}
						}
						&:hover {
							color: $violet;
							&::before {
								right: 0;
								background-color: $violet;
							}
						}
					}
				}
			}
		}
	}
	// menu mobile 
	&.mobile-nav {
		height: auto;
	    position: fixed;
	    z-index: 9999;
	    bottom: -10px;
	    left: 10px;
	    right: 10px;
	    padding: 0;
	    min-height: 40vh;
	    background-color: $dark-blue;
	    border-radius: $radius;
	    overflow: hidden;
	    transition: bottom 1s;
	    &.active {
	    	bottom: 10px;
	    }
	    .nav-wrapper {
	    	position: relative;
	    	z-index: 2;
	    	padding: 40px 15px 20px;
	    }
	    .title {
	    	padding: 0 0 20px;
	    	font-size: $p-size;
	    }
	    ul {
	    	padding: 0;
	    	text-align: center;
	    	margin-bottom: 0;
	    	li {
	    		display: block;
	    		margin: 0 auto 5px;
	    		a {
	    			color: $white;
	    			&.btn-link-mobile {
	    				padding: 5px 10px;
	    				font-size: 18px;
	    				display: block;
	    				font-size: $p-size + 6;
	    			}
	    			&.active {
	    				color: $violet;
	    			}
	    			&.btn-primary {
	    				font-size: 16px;
	    			}
	    		}
	    	}
	    }
 	}
}

section {
	&.generic-banner {
		padding-top: $nav-height;
		padding-bottom: $nav-height;
	}
	&.home {
		position: relative;
		min-height: 80vh;
		background-color: $primary-color;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center;
		background-image: url('/Components/Corporate/assets/images/bone-monitor-image.jpg');
		.gradient-overlay {
			@include gradient;
			top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    position: absolute;
		}
		.container {
			.content {
				margin: 0 0 0 auto;
			}
		}
	}
	.content-wrapper {
		position: relative;
		z-index: 2;
	}
	// FLS Page 
	&.fls-intro {
		background-image: url('/Components/Corporate/assets/images/bone-monitor-outline-violet.svg');
		background-repeat: no-repeat;
		background-position: right -200px top;
		background-size: 600px auto;
	}
	&.contact {
		background-size: auto 100%;
		background-position: left top;
		background-repeat: no-repeat;
		background-image: url('/Components/Corporate/assets/images/bone-monitor-medecin.jpg');
		.content-wrapper {
			padding: $nav-height;
			margin: 0 0 0 auto;
			.title {
				small {
					opacity: 1;
				}
			}
		}
	}
}


.connection-modal {
	height: auto;
	width: 100%;
	bottom: 20px;
	padding: 30px 0 0;
	border-radius: $radius;
	.logo {
		display: block;
		max-width: 200px;
		width: 100%;
		margin: 30px auto;
	}
}


// Form connection
form {
	label {
		&.btn-submit {
			display: block;
			width: 100%;
			margin: 0 auto;
			max-width: 300px;
			.btn-primary {
				text-align: center;
				display: block;
			}
			input {
				display: none;
			}
		}
	}
	input {
		display: block;
		@include font-menu;
		@extend .btnS;
		-moz-appearance: none;
		appearance: none;
		border: none;
		outline: none;
		width: 100%;
		max-width: 300px;
		background-color: $dark-blue;
		border: 2px solid $blue;
		border-radius: $radius;
		color: $white;
		cursor: pointer;
		margin: 0 auto 5px;
		@include transition;
		&:focus {
			color: $white!important;
			background-color: $blue;
		}
		&:hover {
			color: $white;
		}
	}
	.btn-small {
		display: block;
		text-align: center;
		@include transition;
		&:hover {
			color: $white;
			opacity: 1;
		}
	}
}

.two-col-content {
	.image-wrapper {
		img {
			border-radius: $radius;
		}
	}
	.text-wrapper {
		padding-left: 45px;
		padding-bottom: 30px;
	}
}

@media screen and (min-width: $landscape) {
	.sticky-topStyle {
		position: sticky;
		position: -moz-sticky;
		position: -webkit-sticky;
		top: 80px;
	}
}

// Schema 
.schema-wrapper {
	position: relative;
	padding-top: 40px;
	padding-bottom: $nav-height;
	strong {
		display: block;
		text-align: center;
		width: 100%;
		span {
			display: none;
		}
		&.left-text {
			position: absolute;
			left: 0;
			top: 46%;
			width: 25%;
			max-width: 300px;
			text-align: right;
		}
		&.right-text {
			position: absolute;
			right: 0;
			top: 46%;
			width: 25%;
		    max-width: 300px;
			text-align: left;
		}
	}
	img {
		display: block;
		width: 50%;
		max-width: 400px;
		margin: 20px auto;
	}
}

.listing-usp {
	padding-left: 45px;
}

.quotes-content {
	margin-bottom: $nav-height;
}

.quotes {
	padding: 0;
	border-radius: $radius;
	@extend .shadowed;
	.content {
		padding: $nav-height 15px 80px;
	}
	.text-wrapper {
		text-align: center;
		padding: 30px 0 15px;
		a {
			margin-top: 20px;
		}
	}
	.carousel {
		width: 100%;
		margin: 0 auto;
		@extend .max-width-md;
	}

	.flickity-page-dots {
		position: static;
		.dot {
			background: $white;
		}
	}

	.quotes-item {
	  width: 100%;
	  height: auto;
	  margin-left: 40px;
	  margin-right: 40px;
	  border-radius: $radius;
	  text-align: center;
	  /*background-color: darken($violet, 20); */
	  h3 {
	  		text-transform: uppercase;
	  		padding: 30px 20px 10px; 
	  		i {
	  			color: $white;
	  			font-weight: 400;
	  		}
			&::before, 
			&::after {
			  	content: '\f100';
			  	display: inline-block;
			  	color: darken($violet, 20);
			  	font-family: FontAwesome;
			  	margin: 0 10px 0 0;
		  	}
		  	&::after {
		  		margin: 0 0 0 10px;
		  		content: '\f101';
		  	}
		}
	}
}


.contact-list {
	padding: 0;
	margin: 40px 0 0;
	li {
		list-style: none;
		position: relative;
		padding-left: 40px;
		margin: 0 0 10px;
		min-height: 45px;
		font-size: 16px;
		span {
			left: 0;
			width: 35px;
			text-align: center;
			position: absolute;
			i {
				font-size: 24px;
				color: $violet;
			}
		}
		a {
			line-height: 45px;
			@include font-regular;
		}
	}
}

footer {
	.main-footer {
		padding: $nav-height 0 $nav-height/2;;
		margin-bottom: $nav-height/2;
		border-bottom: 2px solid darken($violet,5);
		.logo-wrapper {
			.logo {
				width: 220px;
			}
		}
		.info-wrapper {
			padding-right: 30px;
			p {
				margin: 30px 0 0;
				color: $opac-white;
				opacity: 0.7;
			}
		}
		.contact-wrapper {
			.contact-list {
				margin: 30px 0 0;
				li {
					color: rgba(255,255,255,0.7);
					span {
						i {
							color: $opac-white;
						}
					}
					a {

						color: $opac-white;
					}
				}
			}
		}
	}
	.legal-footer {
		padding-bottom: $nav-height/3;
	}
	.legal-wrapper {
		text-align: left;
		span {
			opacity: 0.7;
		}
	}
	.copy-wrapper {
		text-align: right;
		span {
			opacity: 0.7;
		}
	}
	.footerVersion {
		text-align: right;
		display: block;
		font-size: 16px;
	}
}

/* Privacy and legal pages */
section {
	&.privacy--head {
		.title {
			padding-bottom: 10px;
		}
	}
	&.privacy {
		margin: 0 auto $nav-height;
		h2 {
			@extend .h3;
		}
		h3 {
			@extend .h4;
			@include font-title;
		}
		.text-wrapper {
			padding: $nav-height/2;
		}
		p + h2, 
		p + h3 {
			padding-top: 30px;
		}
		ul {
			padding-left: 20px;
			li {
				list-style: circle;
			}
		}
		a {
			color: $violet; 
			&:hover {
				color: $violet;
			}
		}
	}
}

#cookit.hidden { display: none; }

#cookit {
  	z-index: 999;
	position: fixed;
	background-color: $dark-blue;
	left: 5px;
	right: 5px;
	bottom: 5px;
	width: auto;
	padding: 20px;
	border-radius: $radius;
	text-align: center;
	#cookit-message {
		display: block;
		color: $white;
		margin: 0;
		font-size: $p-size - 2;
		padding: 0 5px 5px;
	}
	#cookit-container {
		a {
			color: $violet;
			margin: 0;
			display: inline-block;
			font-size: $p-size - 2;
			margin-right: 15px;
			&#cookit-button {
				padding: 5px 10px;
				margin-left: auto;
				border-radius: $radius;
				background-color: $white;
				color: $violet;
				font-weight: 400;
				margin-left: auto;
				text-transform: uppercase;
			}
		}
	}
}


@media screen and (min-width: 1600px) {
	section.home {
		background-size: cover;
	}
}
@media screen and (min-width: 2400px) {
	section.home {
	background-size: cover;
	min-height: 60vh;
	}
	section.generic-banner {
		padding-top: 180px;
		padding-bottom: 180px;
	}
	.max-width-md {
		max-width: $container-size - 500;
	}
}

@media screen and (max-width: 1200px) {
	footer {
		.logo-wrapper {
			padding-bottom: $nav-height/2;
		}
	}
}

@media screen and(max-width: $landscape) {
	// typography 
	$p-size: 16px;
	p {
		font-size: $p-size;
	}

	h1, .h1 {
		font-size: $p-size + 24;
	}

	h2, .h2 {
		font-size: $p-size + 20;
	}
	h3, .h3 {
		font-size: $p-size + 14;
	}
	h4, .h4 {
		font-size: $p-size + 10;
	}
	.big-title {
		font-size: $p-size + 28;
	}
	// Content 
	.max-width-sm {
		max-width: $container-size - 570;
	}

	.max-width-md {
		max-width: $container-size - 500;
	}

	.max-width-lg {
		max-width: $container-size - 400;
	}

	.max-width-xl {
		max-width: $container-size - 300;
	}

	// Footer 
	footer {
		.main-footer {
			margin-left: auto;
			margin-right: auto;
			padding-top: $nav-height/2;
			max-width: $container-size - 300;
			.contact-wrapper {
				padding-top: $nav-height/2;
			}
		}
		.legal-footer {
			margin-left: auto;
			margin-right: auto;
			max-width: $container-size - 300;
		}
		.logo-wrapper {
			padding-bottom: $nav-height/2;
		}
	}

	// Home 
	section {
		&.home {
			min-height: 200px;
			background-size: auto 100%;
		}
	}
	.connection-modal {
		.logo {
			margin: 0;
		}
	}
	form {
		a {
			&.btn-small {
				max-width: 300px;
			}
		}
		input {
			margin: 0 0 5px;
		}
		label {
			&.btn-submit {
				margin: 0;
			}
		}
	}

	// FLS page 
	section {
		&.fls-intro {
			background-size: 440px auto;
		}
	}

	// contact 
	section {
		&.contact {
			.container-fluid {
				padding: 0;
			}
		}
	}
}

// responsive portrait
@media screen and(max-width: $portrait) {
	h3, .h3 {
		font-size: $p-size + 12;
	}
	h4, .h4 {
		font-size: $p-size + 4;
	}

	.sticky-topStyle {
		position: static;
	}
	.max-width-lg {
		max-width: $container-size - 500;
	}


	nav {
		ul.menu-list {
			li {
				a {
					margin: 0 15px;
				}
			}
		}
	}

	.quotes,
	.two-col-content,
	footer .main-footer, 
	footer .legal-footer {
		max-width: $container-size - 500;
	}

	.gradient-before {
		&::before {
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(34,39,50,1) 0%, rgba(34,39,50,0.7) 50%, rgba(123,86,159,0.50) 100%);
		}
	}
	section {
		&.home {
			background-repeat: no-repeat;
		    background-position: right top;
		    background-size: 120% auto;
		    .container {
		    	.content {
		    		padding: 0 15px;
		    		margin: 0 auto;
		    	}
		    }
		}
		&.fls-intro {
			padding-top: 200px;
			margin-top: -100px;
			position: relative; 
			z-index: 1;
		}
		&.contact {
			.content-wrapper {
				max-width: $container-size - 600;
			}
		}
	}

	// Fls database 
	#fracture {
		.container {
			position: relative;
		}
	}
	.two-col-content {
		margin: 0 auto;
		position: relative; 
		z-index: 2;
		.text-wrapper {
			padding: 40px;
			margin: 240px auto 0;
			background-color: $white;
			border-radius: $radius;
			box-shadow: 0 0 30px rgba(0,0,0,0.1);
		}
		.image-wrapper {
			position: absolute;
			padding: 0;
			.image-inner-wrapper {
				border-radius: $radius;
				height: 270px;
			    width: 100%;
			    margin: 0 auto 30px;
			    overflow: hidden;
			}
			img {
				width: 100%;
			}
		}
	}
	.schema-wrapper {
		padding-top: 0;
		img {
			width: 40%;
		}
		strong.right-text,
		strong.left-text {
			width: 25%;
		}
	}
	.quotes {
		.content {
			padding: $nav-height/2;
		}
	}

	footer {
		.main-footer {
			.contact-wrapper {
				padding-top: $nav-height/2;
			}
		}
	}
}

@media screen and (max-width: $smartphone) {
	h1, .h1 {
		font-size: $p-size + 16;
	}

	h2, .h2 {
		font-size: $p-size + 12;
	}
	h3, .h3 {
		font-size: $p-size + 9;
	}
	h4, .h4 {
		font-size: $p-size + 6;
	}
	.big-title {
		font-size: $p-size + 20;
	}

	p + a,
	p + ul,
	ul + a {
		margin-top: 20px;
	}

	header {
		padding: 10px;
		position: fixed;
		width: 100%;
		height: 75px!important;
		z-index: 10;
		background: none;
		.container {
			padding: 0;
		}
	}

	section {
		&.generic-banner {
			&.home,
			&.two-col-content {
				padding-top: $nav-height+40;
			}
		}
		&.contact {
			padding-top: $nav-height+20;
		}
	}

	nav {
		.menu-wrapper {
			display: none;
		}
		.logo-wrapper {
			margin: 0 auto;
			text-align: center;
		}
		&.main-nav {
			width: 100%;
			margin: 0 auto;
			background-color: $white;
			border-radius: $radius;
			box-shadow: 0 0 30px rgba(0,0,0,0.1);
			.logo-wrapper {
				img {
					max-height: 50px;
				}
			}
		}
	}

	.mobile-only {
		display: block;
	}

	.gradient-before {
		&::before {
			background: linear-gradient(0deg, #222732 0%, rgba(34, 39, 50, 1) 50%, rgba(123, 86, 159, 0.2) 100%)
		}
	}

	section {
		&.home {
			background-size: 650px auto;
		}
		&.generic-banner {
			padding-top: $nav-height/2;
			padding-bottom: $nav-height/2;
		}
		&.contact {
			background: none;
			.content-wrapper {
				padding: $nav-height/2 30px;
				margin: 0 auto;
			}
			.title-wrapper {
				position: relative;
				padding-left: 80px;
				.title {
					display: block;
				}
			}
			.thumbnail {
				border-radius: 100px;
				height: 80px;
				width: 80px;
				overflow: hidden;
				position: absolute;
				left: -5px;
				top: 5px;
				img {
					width: auto;
					max-width: 100%;
				}
			}
		}
	}

	.connection-modal {
		margin: 0 auto;
		padding-top: 80px;
		.logo {
			margin: 0 auto 30px;
		}
	}

	form {
		input {
			width: 100%;
			margin: 0 auto 5px;
			max-width: 350px;
		}
		label {
			&.btn-submit {
				margin: 0 auto;
				max-width: 350px;
			}
		}
		a {
			&.btn-small {
				margin: 10px auto 0;
			}
		}
	}

	// FLS 
	.two-col-content {
		.container {
			.row {
				padding: 0 15px;
			}
		}
		.image-wrapper {
			position: static;
			.image-inner-wrapper {
				height: 170px;
				margin: 0 auto;
				border-radius: $radius $radius 0 0;
			}
		}
		.text-wrapper {
			margin: 0 auto;
		}
	}

	.quotes {
		max-width: $container-size - 630;
		.text-wrapper {
			padding: 15px 0 0;
		}
	}

	.radial-violet--before{
		&::before {
			width: 100%;
		}
	}

	.schema-wrapper {
		padding-top: 160px;
		img {
			width: 90%;
			max-width: 340px;
		}
		strong {
			font-size: $p-size + 4;
			padding: 0 15px 15px;
			span {
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 auto 10px;
				line-height: 40px;
				font-weight: bold;
				text-align: center;
				color: $white;
				background-color: $violet;
				font-family: $main-font;
				border-radius: 50px;
			}
			&.right-text,
			&.left-text {
				width: 100%;
				max-width: 100%;
				position: static;
				text-align: center;
			}
		}
	}

	.listing-usp {
		padding-left: 15px;
	}


	.contact-list {
		li {
			margin: 0; 
			a {
				display: block;
			}
		}
	}

	// footer 

	.legal-wrapper {
		.btn-link {
			&.white {
				padding: 0;
			}
		}
	}
}

@media screen and (max-width: $small-smartphone) {

	.big-title {
		font-size: $p-size + 16;
	}

	section {
		&.two-col-content {
			padding-top: $nav-height/4;
			.text-wrapper {
				padding: 30px 20px;
			}
		}
		&.fls-intro {
			.content {
				padding: 0 15px;
				.title {
					span {
						display: inline;
					}
				}
			}
		}
		&.contact {
			.thumbnail {
				width: 70px;
				height: 70px;
				margin: 0 10px 30px 0;
			}
		}
	}

	.schema-wrapper {
		padding-bottom: $nav-height/2;
	}

	.quotes-content {
		padding: 0 15px;
	}

	.quotes {
		.content {
			padding: 30px;
		}
	}

	footer {
		.main-footer {
			.info-wrapper {
				padding: 0 15px;
			}
		}
		.logo-wrapper {
			text-align: center;
			.logo {
				max-width: 160px;
			}
		}
		.legal-wrapper,
		.copy-wrapper {
			text-align: center;
		}
	}
}


}

#cookit-button{
	padding: 5px 10px;
	border-radius: 3px;
	background-color: #fff;
	color: #7b569f;
	font-weight: 400;
	margin-left: auto;
	text-transform: uppercase;
}

//login
.loginContainer{
	float: right;
	margin-top: 5%;
	text-align: center;
	width: 370px;
	height: 200px;
}

.caretRight{
	color: white !important;
	margin-bottom:2px;
}

.loginIcon:hover{
	cursor: pointer;
}
.corporate p.loginMsgDetails{
	font-size: 1em;
}

.loginTitle{
	text-align: left;
}

@media only screen and (max-width: 600px) {
	.loginContainer{
		height: 200px;
		position: relative;
	}
	.verCenter{
		margin: 0;
		position: absolute;
		top: 60%;
		height: 200px;
	}
}

//footer
.footerContainer{
	-webkit-transform: translate3d(0, 0, 0);
	will-change: transform;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	height: 50px;
}

.footerFontSize{
	font-size:24px;
}

//home page

@media only screen and (max-width: 600px) {
	.homePageContainer{
		display: none;
	}
}