div.ck.ck-content *{
  color: black;
}

p.createLetter {
  cursor: pointer;
}

.letterElemBorder{
  border-left:2px solid white;
  border-bottom:2px solid white;
}

.marginLeftLettersList{
  margin-left: 30px;
}
.createNewMargin{
  margin-left: 70px;
}
.letterListIco{
  padding-right: 5px;
  margin-bottom: 3px;
}

.letterInModal{
  background:white;
  color:black;
  padding:0;
  margin:0;
  overflow: auto;
  max-height: 80vh;
}

.letterStatus {
  text-transform: uppercase;
}
.letterStatus.DRAFT{
  font-weight: bold;
}