
.colorBtn{
  border-radius: 10px;
  z-index: 1000 !important;
  height: 3.3em;
  min-width: 8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.saveMsg{
  z-index: 100 !important;
  position: sticky ;
  background-color: rgba(0,0,0,0.5);
  padding: 10px;
  border-radius: 0.25rem;
}

.saveMessageContainer{
  position: sticky ;
  z-index: 1000 !important;
  left:95%;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  .savebtnStyle{
    position: fixed;
    z-index: 1000 !important;
    right:20px;
    top:95px;
    max-width: 500px;
  }
}

