.icn-spinner {
  -webkit-animation: spin-animation 0.8s infinite linear;
  animation: spin-animation 0.8s infinite linear;
}

.loaderContainer{
  background-color: rgb(43, 52, 67);
  height: 100vh;
}
.center{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.centerSideOpen{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 580px){
  .center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 33%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .centerSideOpen{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 33%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.centerMini{
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@-webkit-keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}*/
/*0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }*/