@import "src/Sass/AppStyle/SaveBtn/SaveBtn";
@import "src/Sass/AppStyle/Letters/Letters";
@import "src/Sass/AppStyle/Grids/Grids";
@import "src/Sass/AppStyle/Dashboard/Dashboard";
@import "src/Sass/AppStyle/contextHospital/ContextHospital";
@import "src/Sass/AppStyle/Charts/Charts";
@import "src/Sass/AppStyle/AppNavBar/AppNavBarStyle";
@import "src/Sass/AppStyle/AppFooter/AppFooterStyle";
@import "src/Sass/AppStyle/DarkModeSwitch/darkModeSwitch";
@import "src/Sass/AppStyle/CircleProgress/CircleProgress";

.app{
  .btn-primary{
    text-transform: capitalize;
  }
}

//context

.contextPadding{
  padding:20px;
}

@media screen and (max-width: 800px) {
  .contextPadding{
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 10px;
  }
}

//margin height padding
.reportPadding{
  padding-left: 15px;
}

.minScreenHeight{
 // min-height: calc(100vh - 33px);
  flex: 1 0 auto;
}
.minScreenHeightHospital{
 // min-height: calc(100vh - 33px);
  flex: 1 0 auto;
}

.contextContainer{
  //min-height: calc(100vh - 33px);
  flex: 1 0 auto;
}

.marginLeftSpec{
  margin-left: 44.5%;
}
.clinicalSpaceBottom{
  height: 50vh;
}
.adminSpaceBottom{
  height: 50vh;
}

.emptyHeight{
  height:40px;
}

.minTrHeight{
  height: 40px;
}

//error warning default
div.text-start>span>svg.warningMsgColor,p.warningMsgColor{
  color: orange;
}

div.text-start>span>svg.errorMsgColor,p.errorMsgColor{
  color: red;
}

div.text-start>span>svg.defaultMsgColor,p.defaultMsgColor{
  color: white;
}

ul.ulServerMsg li.warningMsgColor::marker{
  color: orange;
}
ul.ulServerMsg li.errorMsgColor::marker{
  color: red;
}

div.WARNING.modal-header{
  background-color: orange;
}

div.ERROR.modal-header{
  background-color: red;
}

div.INFO.modal-header{
  background-color: deepskyblue;
}

//li

.centerMainDiv{
  max-width: 1330px;
  li{
    margin-left: 3.2em;
  }
}

//split word
.breakWord{
  white-space: pre-line;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

//row col
.row {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: -15px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
 // width: 100%;
  padding-right: 5px;
  padding-left: 15px;}

//select placeholder
.textOptionBlack{
  color: black;
  text-transform:uppercase;
}

.textOptionBlackSpecial{
  color: black;
  text-transform:uppercase;
}

@media (max-width: 800px) {
  .textOptionBlack{
    color: black;
    text-transform:uppercase;
  }
  .marginBotOnSmall{
    margin-bottom: 10px;
  }
  .textOptionBlackSpecial{
    color: black;
    text-transform:uppercase;
  }
}

//hr
.inSectionAdmin{
  width: 91%;
  margin-right:100%
}
.inSection{
  margin: auto;
  margin-top: 20px;
  margin-bottom:20px;
  width: 80%;
}
.inSectionSpecial{
  max-width: 90%;
}
//cursor
.newCursor{
  cursor: pointer;
}

//task
.taskContainer{
  min-height: calc(100vh - 115px);
}

.taskRow.taskState-NEW, .taskRow.taskState-DRAFT{
  .taskDueDate, .taskName {
    font-weight: 700 !important;
  }
}

/*.col p {
  color: #ccc !important;
}*/


form input.react-datepicker-time__input{
  background-color: white;
  color: black;
  &:hover{
    color: black !important;
  }
  &:focus{
    background-color: white !important;
    color: black !important;
  }
}

.centerMainDiv li.react-datepicker__time-list-item  {
   margin-left: 0;
}

.cardElem{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 10px;
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
}


@media screen and (max-width: 800px) {
  .cardElem{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
  }
}

.maxWlabel{
  margin-left: 20px;
}

.whiteSpinner{
  color: white !important;
}
