.btn {
  display: inline-block;
  line-height: 40px;
  padding: 0 50px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
  color: #fff;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
}

.modal-header .close {
  padding: 0 1rem;
}

.close {
  font-size: 3rem;
  color: white;
  text-shadow: none;
  opacity: 1;

  &:hover{
    color: #7B569F;
  }
}
.cancelBtn{
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  &:hover{
    background-color: #7c757d;
  }
}

.customModalBtn{
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  border:0px;
  border-radius: 5px;
  color: white;
}

.customBtn{
  border-radius: 5px;
  background-color: #2b3443;
  cursor: pointer;
  border: 1px solid white;
  padding: 2px 8px 5px 8px;
  color: white;
}
.customBtn:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}

.btn--radius {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.btn--radius-2 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.btn--pill {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.btn--addPatient{
  border: 1px solid white;
  padding: 0 15px;
}

.discardBtn{
  line-height: 20px;
}
.saveAsDraftBtn{
  line-height: 40px;
}

.finalizeBtn{
  line-height: 20px;
}

.deleteBtn{
  line-height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  border:none;
}

.btn{
  padding: 0.375rem .75rem;
}

