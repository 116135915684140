@import "GeneralStyle/Button";
@import "GeneralStyle/Input";
@import "GeneralStyle/backGround";
@import "GeneralStyle/Radio-Checkbox";
@import "GeneralStyle/scrollBar";
@import "GeneralStyle/loader";
@import "GeneralStyle/visualBoneSelector";
@import "src/Sass/App";
@import "LoadBrand";


//import font
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  src: local('RobotoCondensed'), url(./Fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  src: local('RobotoSlab'), url(./Fonts/Roboto_Slab/RobotoSlab-Regular.ttf) format('truetype');
}

*{
box-sizing: border-box;
}

:root {
  --bodyFontSize:16px;
};
[data-direction='ltr']{
  --langDirection:ltr;
  --align:left;
}
[data-direction='rtl']{
  --langDirection:rtl;
  --align:right;
}
[data-blind='SMALL'] {
  --bodyFontSize:12px;
  --bodyLineHeight:1.5;

  --sidebarIco:15px;
  --navbarIco:15px;
  --navbarIcoMenu:20px;
  --contextIco:20px;
  --contextIcoSmall:15px;
  --contextIcoLabelSmall:14px;

  --checkbox:20px;
  --checkboxCheckWidth:5px;
  --checkboxCheckHeight:10px;
  --checkboxCheckLeft:7px;
  --checkboxCheckTop:2px;
  --checkboxMt:7px ;
  --checkboxMb:0px ;

  --radio:20px;
  --radioAfter:12px;
  --radioMt:0px;

  --containerOpenDash:10px;

  --inputTextLineHeight:30px;

  --indicatorContainer:3px;

  --minWidthDropDown:11rem;
  --navFontSize:0.8em;
  --headerHospital:0.8em;
  --expandImg:0;
  --sidebarIcoAlign:sub;
  --sidebarIcoChevV:bottom;
  --textAreaLineHeight:1.5rem;
  --btnBorderedWidth:150px;
  --loginTextMaxWidth:540px;
  --tooltipSize:0.9em;
  --ckTooltipSize:0.9em;
  --footerFontSizeBigger:0.8em;
};

[data-blind='NORMAL'] {
  --bodyFontSize:16px;
  --bodyLineHeight:1.5;

  --sidebarIco:25px;
  --navbarIco:25px;
  --navbarIcoMenu:25px;
  --contextIco:25px;
  --contextIcoSmall:20px;
  --contextIcoLabelSmall:14px;

  --checkbox:26px;
  --checkboxCheckWidth:5px;
  --checkboxCheckHeight:15px;
  --checkboxCheckLeft:10px;
  --checkboxCheckTop:2px;
  --checkboxMt:5px ;
  --checkboxMb:0px ;

  --radio:26px;
  --radioAfter:19px;
  --radioMt:5px;

  --containerOpenDash:100px;

  --inputTextLineHeight:35px;

  --indicatorContainer:8px;

  --minWidthDropDown:16rem;
  --navFontSize:0.8em;
  --navFontSizeBigger:0.8em;
  --footerFontSizeBigger:0.8em;
  --headerHospital:1em;
  --expandImg:0;
  --sidebarIcoAlign:sub;
  --sidebarIcoChevV:bottom;
  --textAreaLineHeight:1.5rem;
  --btnBorderedWidth:160px;
  --loginTextMaxWidth:540px;
  --tooltipSize:0.9em;
  --ckTooltipSize:0.9em;
};

[data-blind='LARGE'] {
  --bodyFontSize:24px;
  --bodyLineHeight:1.5;

  --sidebarIco:25px;
  --navbarIco:25px;
  --navbarIcoMenu:30px;
  --contextIco:30px;
  --contextIcoSmall:25px;
  --contextIcoLabelSmall:25px;

  --checkbox:32px;
  --checkboxCheckWidth:10px;
  --checkboxCheckHeight:20px;
  --checkboxCheckLeft:10px;
  --checkboxCheckTop:2px;
  --checkboxMt:0px ;
  --checkboxMb:5px ;

  --radio:32px;
  --radioAfter:25px;
  --radioMt:15px;

  --containerOpenDash:150px;

  --inputTextLineHeight:50px;

  --indicatorContainer:8px;

  --minWidthDropDown:24rem;
  --navFontSize:0.8em;
  --navFontSizeBigger:0.5em;
  --footerFontSizeBigger:0.8em;
  --headerHospital:0.7em;
  --expandImg:7rem;
  --sidebarIcoChevV:bottom;
  --sidebarIcoAlign:baseline;
  --textAreaLineHeight:2rem;
  --btnBorderedWidth:300px;
  --loginTextMaxWidth:650px;
  --tooltipSize:1.3em;
  --ckTooltipSize:1.3em;
};

[data-blind='HUGE'] {
  --bodyFontSize:32px;
  --bodyLineHeight:1.5;

  --sidebarIco:30px;
  --navbarIco:30px;
  --navbarIcoMenu:25px;
  --contextIco:30px;
  --contextIcoSmall:30px;
  --contextIcoLabelSmall:30px;

  --checkbox:38px;
  --checkboxCheckWidth:12px;
  --checkboxCheckHeight:20px;
  --checkboxCheckLeft:12px;
  --checkboxCheckTop:3px;
  --checkboxMt:0px ;
  --checkboxMb:5px ;

  --radio:40px;
  --radioAfter:32px;
  --radioMt:10px;

  --containerOpenDash:200px;

  --inputTextLineHeight:50px;

  --indicatorContainer:8px;

  --minWidthDropDown:24rem;
  --navFontSize:1em;
  --navFontSizeBigger:0.5em;
  --footerFontSizeBigger:0.8em;
  --headerHospital:0.7em;
  --expandImg:7rem;
  --sidebarIcoAlign:baseline;
  --sidebarIcoChevV:bottom;
  --textAreaLineHeight:2rem;
  --btnBorderedWidth:300px;
  --loginTextMaxWidth:750px;
  --tooltipSize:1.5em;
  --ckTooltipSize:1.5em;
};

html {
  scroll-behavior: smooth;
  font-size:var(--bodyFontSize);
}
.fontMainSize{
  font-size: var(--bodyFontSize);
  line-height: var(--bodyLineHeight);
  height:100vh;
}

.modal-dialog{
  font-size: var(--bodyFontSize);
  line-height: var(--bodyLineHeight);
}



.CKEditorContainer{
  font-family: 'Geneva','Tahoma','Verdana','sans-serif' !important;
}

html {
  scroll-behavior: smooth;
}

.container{
  padding-top: 20px;
  padding-bottom: 20px;
}

.kpiContainer{
  max-width: 100% !important;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: "Roboto","Roboto Condensed","Roboto Slab","Segoe UI",Tahoma,sans-serif;
  font-size: var(--bodyFontSize);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//full height

.fullHeight{
  min-height: calc(100vh - 196px);
}

//h6


h6{
  text-align: left;
  font-weight: bold;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

h6.inlineRadioTitle{
  text-align: left;
  font-weight: bold;
  min-height: 20px;
  margin-top: 10px;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@media screen and (max-width: 599px){
  h6{
    text-align: left;
    font-weight: bold;
    margin-top: 15px;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}



//margin

div.form-group.row.rowMargin{
  margin-bottom: 5px;
  margin-top: 5px;
}

div.row{
  margin-bottom: 0px;
  margin-top: 0px;
}

//margin left

.inlineRadioMargin{
  margin-left: -20px!important;
}


.addressMargin{
  margin-left:-7% !important;
}

@media screen and (max-width: 1000px){
  .addressMargin{
    margin-left:-15px !important;
  }
}


.addressMarginAdmin{
  margin-left:-7.1% !important;
}
@media screen and (max-width: 1000px){
  .addressMarginAdmin{
    margin-left:-3% !important;
  }
}


@media screen and (max-width: 990px){
  .addressMarginAdmin{

  }
  .addressMargin{

  }
  .rowMargin{

  }
}


//padding

.specialPad{
  padding-right: 45px !important;
}

.reportPadding{
  padding-left: 15px;
}


//height


.specialHeight{
  height: 40px;
}
@media screen and (max-width: 580px){
  .specialHeight{
    height: 70px;
  }
}


//label

.unit{
  vertical-align: middle;
  margin-top: 5px;
}

.labelRightOrLeft{
  text-align: right;
}

.labelRightOrLeft2{
  text-align: right;
}
@media screen and (max-width: 991px){
  .labelRightOrLeft2{
    text-align: left;
  }
}

@media screen and (max-width: 800px){
  .labelRightOrLeft{
    text-align: left;
  }
}

label{
  //padding-left: 5px;
  text-align: right;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

label.labelLeftOnly{
  text-align: left;
}

.specialLabel{
  margin-top:7px;
  padding-left: 5px;
  color: #ccc ;
}


input[type='checkbox'] + label {
  line-height: 35px;
  display: inline;
  vertical-align: top;
  cursor: pointer;
  margin-left: 4px;
  margin-top: 7px;
}



@media screen and (max-width: 1000px){
  label.labTest{
    text-align: left;
  }
}


@media screen and (max-width: 1000px){
  label{
    text-align: left;
  }
}


//margin top

.margTot{
  margin-top: 1rem;
}

//clinical
div.inlineRadioMargin>div>div.form-check-inline{
  min-width: 100px;
}
.contextContainer h4{
  font-weight: bold;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  span{
    color:#6e4d8e;
    margin-right: 5px;
    position: relative;
    top: -4px;
  }
}
.titleMarginTop{
  margin-top: 40px;
}
.contextPadding h5{
  font-weight: bold;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 2rem;
}

.selectBotMargin{
  margin-bottom: 3px;
}

ol{
  padding: 0;
}

hr {
  height: 0;
  margin-top: 40px;
  margin-bottom:40px;
}

hr.titleHr{
  margin-top:0px;
}

hr.inSection{
  margin: auto;
  margin-top: 20px;
  margin-bottom:20px;
  width: 80%;
}

.section{
  margin-bottom: 0;
}

.form-check{
  padding-left: 0 !important;
}


.fallRiskMinHeight{
  min-height: 50px;
}


.verticalInput{
  display: block;
  margin-top: 5px;
}

.baseSelect{
  margin-bottom:5px;
  color: black;
  .css-yk16xz-control{
    border-radius: 4px !important;
    color: white;  }

}

.minHeightBoneContainer{
  min-height: 350px;
}

.subTitleHr{
  margin-left:0px;
  margin-top: 5px;
  width: 90%;
  height: 1px;
}
.titleIcon{
  position: relative;
  top: -5px;
}

//margin in clinical
.fallRiskMargin{
  margin-left: -7% !important;
}
@media screen and (max-width: 1000px){
  #fallReco label.fallRiskMinHeight{
    text-align: left;
  }
  .fallRiskMargin{
    margin-left: -15px !important;
  }
}
.marginLeft{
  margin-left: -55px;
}
.spineOtherMargin{
  margin-left: 130px;
}
@media screen and (max-width: 900px) {
  .spineOtherMargin{
    margin-left: 0px;
  }
}
@media screen and (max-width: 1210px){
  .marginTopOther{
    margin-top: 5px;
  }
}
.rowSpace{
  margin-bottom: 5px !important;
}
@media screen and (max-width: 600px) {
  .alignLeftSM{
    float: left;
  }
  .hipAlign{
    margin-top: 20px;
    margin-left: 15px;
  }
}
.prevList{
  margin-left: 0px;
}

.scrollable {
  overflow-y: auto;
}

.scrollableH {
  overflow-y: auto;
  max-height: 700px;
}

.scrollableSmall {
  overflow-y: auto;
  min-height: 100px;
  max-height: 250px;
}

.selectInPrevOsteoModal{
  color: black;
}

.specialContainer{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
}
.inputWidthBoneDensity{
  width: 215px;
}

//info
.infoCalmodalLink p{
  cursor: pointer;
  color:orange;
  text-align:left;
}

.infoCalmodalLink p:hover{
  color:#6e4d8e;
}

@media (max-width: 1300px) {
  .infoCalmodalLink p{
    cursor: pointer;
    color:orange;
    text-align: left;
  }
}

//monitoring
.containerMonitoring{
  min-height: calc(100vh - 115px);
  margin-bottom:50px;
}

.previewImg{
  max-width:100%;
  height: auto;
  position: relative;
  margin-top:10px;
  margin-bottom:10px;
  left: 50%;
  transform: translateX(-50%);
}

.marginImport{
  margin-top:20px!important;
}
.optionalSelect{
  text-transform: capitalize;
  padding-left: 5px;
  padding-right: 5px;
}

.table th, .table td {
  padding-left: 5px !important;
}

.minHeightTable{
  //min-height: 50vh;
}
.minHeightGridDashboard{
  min-height: 50vh !important;
}

.fixedHeightTr{
  height:1em !important
}

.taskListDash{
  min-height: 57vh !important;
}
.drugIcoPr{
  padding-right: 15px;
}

.drugNameTrPr{
  padding-left: 15px;
}


.drugDeleteIcoPr{
  padding-right: 15px;
}

.tableHeaderFontWeight{
  font-weight: 800;
}


.gridNameTrPl {
  margin-left: 15px !important;
}

.gridNameTrPr {
  margin-right: 15px !important;
}

.emptyPagi{
  background-color: transparent !important;
  border: none !important;
  &:hover{
    background-color: transparent !important;
  }
}


.paddingRight{
  padding-right: 20px;
}

.textPosition{
  text-align: right !important;
}

@media screen and  (max-width: 770px) {
  .paddingStart{
    padding-left:0 !important;
  }
  .textPosition{
    text-align: right !important;
  }
}

.minWidthAccountDropdown{
  min-width: var(--minWidthDropDown);
}


//blind mode

#blind-nav-dropdown{
  padding: 0px !important;
  margin: 0px !important;
}

.fontSmall{
  font-size:11px;// 0.7em;
}
.fontNormal{
  font-size:16px;// 1em;
}
.fontLarge{
  font-size:24px;// 1.5em;
}
.fontHuge{
  font-size: 32px;//2em; and then apply rem to all font size value
}

.textPSize{
  font-size: 0.937em !important;
  line-height: 1.25em !important;
}


/********** Font size selector *******************/
a,div.text-start.alignP,td,th,button,input,textarea,[class$="-control"],[class$="-menu"],li,li>span{
  font-size: 1em;
}

p{
  font-size: 1.125em;
}

//sidebar
div.sidebarMenuItem {
  font-size: 1.125em;
}
div.sidenavOpen.sidebg.sideShadow p {
  font-size: 1.3125em;
}
h4{
  font-weight: normal !important;
}

.sidebarIcoChev{
  width: 1.125em;
  height:1.125em;
  vertical-align: var(--sidebarIcoChevV);
}

//login special size
div.content > p {
  font-size: 1.125em;
}

form label.btn-submit {
  font-size: 1em !important;
}

.btn{
  font-size: 1em;
  line-height:2.3em;
  min-width: 100px;
}


button.btnBordered{
  font-size: 1em;
}
/*
div.react-grid-item.react-draggable.react-resizable-hide.react-resizable>div{
  height:4em !important;
}
*/
li::marker{
font-size:1em;
}
.settingsLi{
  //font-weight: 700!important;
}
li.settingsLi::marker{
  font-size:1.25em;
  //font-weight: 700!important;
}
h5{
  font-size:1.25em;
}
.section>li::marker{
  font-size: 1.25em;
  font-weight: 700!important;
}
.section>div>li::marker{
  font-size: 1.25em;
  font-weight: 700!important;
}
.section>div>div>li::marker{
  font-size: 1.25em;
  font-weight: 700!important;
}


div.modal-title h4, .h4,h4{
  font-size:1.5em;
}

div.unit{
  font-size:0.875em !important;
}

tspan.unit{
  font-size:0.65em !important;
  vertical-align:bottom;
}

span.unit{
  font-size:0.65em !important;
  vertical-align:bottom;
}


label{
  font-size:1em !important;
}
h6{
  font-size:0.94em;
}

.dropdown-menu {
  font-size: 1em;

  max-height: 90vh;
  overflow-y: auto;
}

.timeRange.dropdown-menu{
  font-size: 1em;
  min-width: 550px;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: inherit;

}

@media screen and  (max-width: 850px){
  .timeRange.dropdown-menu{
    font-size: 1em;
    min-width: 300px;
    max-width: 550px;
    max-height: 90vh;
    overflow-y: inherit;
  }
}


div.headerHospitalName{
  font-size: var(--headerHospital);//1em;
}
div.headerHospitalDepartment {
  font-size: var(--headerHospital);//1em;
}

@media screen and  (max-width: 700px){
  div.headerHospitalName{
    font-size: 0.4em;
  }
  div.headerHospitalDepartment {
    font-size: 0.4em;
  }
}

div.navbar-nav>ul>li.truncate{
  line-height: 30px;
}
div.navbar-nav>ul{
  margin-top: 15px;
}
.liNav{
  font-size: var(--navFontSizeBigger) !important;
}
.appNavItem{
  font-size: var(--navFontSizeBigger) !important;
}

.dopdownAItem{
  font-size: 1em !important;
}




  /**** Ico size ***/
svg.sidebarIco{
  width:var(--sidebarIco)!important; //20px !important;
  height: var(--sidebarIco)!important; //20px !important;
  vertical-align:  var(--sidebarIcoAlign);
}
svg.navbarIco{
  width:var(--navbarIco)!important; // 20px !important;
  height:var(--navbarIco)!important; // 20px !important;
  vertical-align: sub;
}
svg.navbarIcoMenu{
  width:var(--navbarIcoMenu)!important; // 25px !important;
  height:var(--navbarIcoMenu)!important; // 25px !important;
  vertical-align: sub;
}
svg.contextIco{
  width:var(--contextIco)!important; // 25px !important;
  height:var(--contextIco)!important; // 25px !important;
  vertical-align:var(--sidebarIcoAlign);;
}
svg.contextIcoSmall{
  width:var(--contextIcoSmall)!important; // 20px !important;
  height:var(--contextIcoSmall)!important; // 20px !important;
  vertical-align: sub;
}
svg.contextIcoLabelSmall{
  width:var(--contextIcoLabelSmall)!important; // 20px !important;
  height:var(--contextIcoLabelSmall)!important; // 20px !important;
  vertical-align: middle;
}

/*** Radio/check btn ****/
input[type='checkbox']{
  margin-top: var(--checkboxMt) !important;
  margin-bottom: var(--checkboxMb) !important;
  width:var(--checkbox)!important; //26px !important;
  height:var(--checkbox)!important; // 26px !important;
}
input[type='checkbox']:not(.switch):after {
  width:var(--checkboxCheckWidth)!important; //5px !important;
  height:var(--checkboxCheckHeight)!important; // 15px !important;
  left:var(--checkboxCheckLeft); // 10px;
  top:var(--checkboxCheckTop); // 2px;
}
input[type='radio']{
  margin-top: var(--radioMt)!important;
  width:var(--radio)!important; //26px !important;
  height:var(--radio)!important; // 26px !important;
}
input[type='radio']:after{
  width:var(--radioAfter)!important; //19px !important;
  height:var(--radioAfter)!important; // 19px !important;
}


.input--style-4 {
  line-height: var(--inputTextLineHeight);
}


[class$="indicatorContainer"] {
  padding: var(--indicatorContainer) !important;
}
[class$="-control"] {
  min-height: 0px !important;
}

.dmSelect{
  min-width: 80%;
}
//kpi legend font size (fixed value because its going over in huge mode)
tspan{
  font-size: 16px;
}
//blind mode inside modal




.searchBar{
  position: sticky;
  position: -webkit-sticky;
  bottom: 0px;
  width: 100%;
  z-index: 0;
  padding: 20px;
  border-radius: 5px;
}

.linkSpan{
  text-decoration: underline;
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
}

.minScreenHeightSearch {
  //min-height: calc(100vh - 32px);
  flex: 1 0 auto;
}

.customButton{
  cursor: pointer;
  display: inline-block;
  line-height: 40px;
  padding: 0 50px;
  transition: all 0.4s ease;
  border-radius: 3px;
  text-transform: capitalize!important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  margin: 0;
}

.SearchBarList{
  display: flex;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-direction: row;
  flex-wrap:  wrap ;
  justify-content: center;
  margin: 0;
  padding: 0;
}



.react-grid-item.react-grid-placeholder.react-draggable.cssTransforms.react-resizable-hide.react-resizable{
  width: 10% !important;
  background-color: black!important;
}

.react-draggable-dragging{
 background-color: transparent !important;
}
.react-grid-item.react-grid-placeholder {
  width: 100% !important;
}
.fullScreen{
  height: 100vh;
  display: flex;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-direction: column;
}
.heightWithFooter{
  //min-height:calc(100% - 32px)!important;
  flex: 1 0 auto;
}

.customLink{
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
}

.patientListTitle{
  margin-top: 20px;
  min-height: 38px;
  padding-bottom: calc(.375rem + 1px);
  padding-top: calc(.375rem + 1px);
}


.inputSearchCont{
  padding-left: 15px;
  padding-right: 5px;
}

.specialGridMargin{
  margin-left: 15px;
}
@media only screen and (max-width: 990px) {
  .inputSearchCont{
    width: 50%;
    margin: auto;
  }
  .specialGridMargin{
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .inputSearchCont{
    width: 100%;
    margin: auto;
  }
  .specialGridMargin{
    margin-left: 0px;
  }
}

.divBtn{
  width: 100px;
}

.button1{
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.7em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375em 0.75em;
  font-size: 1em;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  min-width: 125px;
}

div[role='userMenu']{
  min-width: 12rem!important;
  max-width:none!important;
}

div[role='tripleDotMenu']{
 min-width: 350px!important;//350px
 // max-width:500px!important;
}
@media screen and (max-width: 800px) {
  div[role='tripleDotMenu']{
    min-width: 85vw!important;//350px
    max-width:85vw!important;
  }
}



.expanded{
  margin-bottom: var(--expandImg);
}

textarea{
  line-height: var(--textAreaLineHeight);
}

.btnBordered{
  width: var(--btnBorderedWidth) !important;
}

div.dashboardCont p.patientCount{
  font-size: 0.8em;
}

//login page

div.content > h1 {
  font-size: 2.75em !important;
}

.max-width-md{
  max-width: var(--loginTextMaxWidth);
}

//tooltip
div.tooltip{
  font-size: var(--tooltipSize) !important;
  //font-size: 0.9em;
}

//datepicker
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 1.7em!important;
  line-height: 1.7em!important;
}

.react-datepicker {
  font-size: 1em!important;
}

//kpi chart

tspan{
  font-size: 16px;
}

//ck tooltip

.ck.ck-tooltip .ck-tooltip__text{
  font-size: var(--ckTooltipSize)!important;
}

h3{
  font-size:1.875em !important ;
}

.appFooterContainer{
  font-size: var(--footerFontSizeBigger) !important; //--navFontSizeBigger
  min-height:32px!important;
  //footerVersion
  flex-shrink: 0;
}

.descri li, .descri p, .descri div{
  font-size: 1.125em;
}

.saveMsg{
  font-size: 1.25em;
}

main.content{
  flex: 1 0 auto;
}

.flexedContent{
  flex: 1 0 auto;
}

div .rootBackground{
  display: flex;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-direction: column;
}

.imageResize{
  height: auto;
  width: auto;
  max-width: 200px;
  max-height: 200px;
}
.imageContainer{
  margin: auto;
  width: 100%;
  overflow: hidden;
}


.previewImgContainer{
  margin: auto;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
}

.inlineSelectTxt{
  padding: 7px;
  padding-right: 0px;
}

.minHeightSection{
  min-height: 350px;
}

.centerSvg{
  transform: translateX(19%);
}

div.spineOverviewPosition > p{
  font-size: 16px;
}

.xScrollbarHide{
  overflow-x: hidden !important;
}

.ck{
  direction: var(--langDirection) !important;
}
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners{
  direction: var(--langDirection) !important;
}
.ck.ck-editor__editable_inline[dir=ltr]{
  text-align: var(--align) !important;
}
.ck.ck-toolbar{
  direction: var(--langDirection) !important;
}
.direction{
  direction: var(--langDirection);
}