.metric {
  path {
    stroke-linecap: round;
    stroke-width: 6;
  }
  path.grey {
    stroke: #e7e7e8;
  }
  path.blue {
    stroke-dasharray: 198;
    animation: dash 3s ease-out forwards;
  }
}

.cardMetric {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
}

.cardMetric:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.containerCardMetric {
  padding: 2px 16px;
  position: absolute;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.minHeightMetricTitle{
  min-height: 70px;
}

