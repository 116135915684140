.recharts-responsive-container {
  margin : 0 auto;
  background-color: #f1f1f1;
}

.recharts-wrapper{
  padding-right: 40px;
}

.recharts-text {
  color: #f1f1f1;
}

.recharts-label{
  color: #f1f1f1;
}

.recharts-legend-wrapper{
  position: absolute;
  width: 1164px;
  height: 36px;
  left: 0px;
  bottom: 10px !important;
}

.footAnnot{
  margin-left: 2em;
}

.descri{
  margin-top: 3em;
}

.kpiCont{
  padding: 10px !important;
  max-width: none !important;
  background-color: #2b3443;
}
