.addSearchCont{
    text-align: right;
    margin-top: 45px;
    padding-top: 25px;
    margin-bottom: 10px;
}
.addSearchInput{
    width: 280px;
    //height: 38px;
    //border: 2px solid #d7dade;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}
.leftMargDash{
    margin-left: -5px !important;
}
div.dashboardCont p.patientCount{
    margin: auto;
    margin-bottom: 10px;
    color: #ccc;
}


.btnBordered{
    border-radius: .25rem;
    user-select: none;
    padding: 3px 7px;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 3px;
    width: 150px;
}


@media screen and (max-width: 1300px) {
    .addSearchCont{
        text-align: center;
        margin-top: 45px;
        padding-top: 25px;
        margin-bottom: 10px;
    }

    div.dashboardCont p.patientCount{
        margin-right:0px;
        color: #ccc;
    }
}

@media screen and (max-width: 1000px) {
    div.dashboardCont p.patientCount{
        color: #ccc;
    }
}


//header
.containerOpenDash {
    position: relative;
    min-height:170px;
}

.containerCloseDash {
    margin:auto;
    text-align: center;
    width: 90%;

}

.imgStyle{
    max-height: 300px;
    object-fit: cover;
    border-radius:0px 0px 5px 5px;
    opacity: 1;
    transition: all .4s ease;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;

}
.imgStyleClose{
    width: 95%;
    height: 0px;
    transition: 0.5s;
}
/* Bottom left text */
.bottom-left-info {
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0px;
    position: absolute;
    margin-right: 5px;
    top: 50px;
    left:10px;
    text-align: center;
}


.textHeaderSize{
    font-weight: 400;
   /* line-height: 20px;*/
}


.dashboardCont{
    max-width: 1440px;
    margin: auto;
    min-height: calc(100vh - 129px);
}

.toggleHeader{
    color: #7B569F;
    cursor: pointer;
    font-weight: bolder;
    position: absolute;
    bottom: 0%;
    right: 5%;
    margin-right: 5px;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
}

.backgroundToggleHeader{
    //background-color: rgba(0,0,0,0.6);
    padding-left: 10px;
    padding-right: 10px;

}

.toggleHeader2{
    color: #7B569F;
    cursor: pointer;
    font-weight: bolder;
    padding: 5px;
    margin-right: 40px;
    text-align: right;
}

.toggleHeader:hover{
    color:grey;
}

.showImage{
    display: block;
    animation: fade-in 1s;
    transition: .5s ease;
}
.hideImage{
    display: none;
    animation: fade-out 1s;
    transition: .5s ease;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media only screen and (max-width: 600px) {
    .bottom-left {
        background-color: black;
        padding: 10px;
        position: absolute;
        bottom: 70px;
        left: 0px;
    }
}

//task list

.colDashboardGrid{
   // margin-bottom:100px;
    vertical-align:top;
}

.dashboardGrid{
    margin: 0;
    width: 100%;
    border-radius: 5px;
    border-collapse: separate!important;
}

.compactGrid{
    margin-top:1px !important;
    margin-bottom:1px !important;
    padding:3px !important;
}

.dashboardGrid
{
    border-radius: 5px !important;
    border-spacing: 0px;
    td{
        border-top:0;
    }
    th{
        font-weight: normal;
        border-top:0;
    }

}
.table th{
    background-color: rgba(0,0,0,.05);
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,0);
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0,0,0,.03);
}
.table td, .table th {
    padding: .50rem;
    vertical-align: top;
}
.tableTaskCellSize{
    max-width: 300px;
}

.tableCellSize{
    min-width: 100px;
}
.tableCellSize2{
    min-width: 130px;
}
.tableCellSize3{
    min-width: 145px;
}

.item {
    margin: 5px;
    padding: 5px;
}
.specialItemHover{
    margin: 5px;
    padding: 5px;
}

.item:hover {
    cursor: pointer;
}

.scrollBarStyle{
    .scrollableTaskGrid{
        max-height: 800px;
        overflow-y: scroll;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #FFF;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .emptyRow td
    {
        text-align: center;
    }
}

.selectedDirIcon svg{
    cursor: pointer;
}
.notSelectedDirIcon svg{
    cursor: pointer;
}

