@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  .noWhiteCircle{
    input[type='radio'] {
      --active: #fff;
      --active-inner: black;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #275EFE;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 26px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      margin-top: 5px;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      /*transition: background .3s, border-color .3s, box-shadow .2s;*/
      &:after {
        content: '';
        display: inline;
        left: 0;
        top: 0;
        position: absolute;
        /*transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);*/
      }
      &:checked {
        /*--b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;*/
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:focus {
        color: #666!important;
        background-color: #fafafa;
      }
    }

  }
  input[type='checkbox']{
    --active: #fff;
    --active-inner: black;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 26px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    /*transition: background .2s, border-color .2s, box-shadow .1s;*/
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      /*transition: transform var(--d-t, .2s) var(--d-t-e, ease), opacity var(--d-o, .1s);*/
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      /*--d-o: .3s;
      --d-t: .6s;*/
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
        opacity: .5;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 26px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
    &:focus {
      color: #666!important;
      background-color: #fafafa;
    }
    &:hover {
      color: #666;
    }
  }


  input[type='radio'] {
    --active: #fff;
    --active-inner: black;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 26px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    /*transition: background .3s, border-color .3s, box-shadow .2s;*/
    &:after {
      content: '';
      display: block;
      left: 3px;
      top: 3px;
      position: absolute;
      /*transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);*/
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 26px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      line-height: 21px;
      //display: inline-block;
      vertical-align: middle;
      vertical-align: -moz-middle-with-baseline;
      cursor: pointer;
      margin-left: 4px;
      display: inline;
      vertical-align: -webkit-baseline-middle ;
    }
    &:focus {
      color: #666!important;
      background-color: #fafafa;
    }
  }

  input[type='checkbox'] {
    margin-top: 5px;
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 15px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 10px;
        top: 2px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }

  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}

.centerCheckMark{
  margin-top: 5px;
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 6px !important;
        top: 1px !important;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 20px;
        top: 20px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 170px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
}

.errorClinical{
  border: 4px solid red !important;
}
.mandatoryClinical{
  border: 4px solid orange !important;
}
.mandatoryClinicalRadio{

}
.errorClinicalRadio{

}
div .mandatoryClinicalRadio input{
  border: 4px solid orange !important;
}

div .errorClinicalRadio input{
  border: 4px solid red !important;
}

.form-check .form-check-input{
  float: none;
  margin-left: 0;
}


