form input,.input--style-4{
  width: 100% ;
  max-width: 100%;
  margin: 0;
}
.disableBackground{
  background-color: #dddddd !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.input--style-4 {
  cursor:text;
  width: 100%;
  max-width: 100%;
  outline: none;
  border: none;
  font-family: inherit;
  line-height: 35px;
  background: #fafafa;
  -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0 5px;
  color: #666;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &:focus {
    color: #666!important;
    background-color: #fafafa;
  }
  &:hover {
    color: #666;
  }
}

.groupText{
  line-height: 35px;
  padding: 0 5px;
  font-size: 16px;
  margin-bottom: 5px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}


@media screen and (max-width: 580px){
  .input--style-4 {
    cursor:text;
    width: 100%;
    max-width: 100%;
    outline: none;
    border: none;
    font-family: inherit;
    line-height: 35px;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 5px;
    color: #666;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .react-datepicker-wrapper {
    display: inline !important;
    padding: 0;
    border: 0;
  }
  form input ,.input--style-4{
    width: 100%;
    max-width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.adminCityInput{
  width:91%;
}

input[type=text]:disabled {
  background: #dddddd;
}

textarea{
  cursor:text;
  outline: none;
  margin: 0;
  border: none;
  font-family: inherit;
  line-height: 22px;
  background: #fafafa;
  -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #666;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  min-width:150px;
  max-width:100%;
  min-height:150px;
  height:100%;
  width:100%;
  padding: 12px 20px;
  box-sizing: border-box;
  &:focus {
    color: #666!important;
    background-color: #fafafa;
  }
  &:hover {
    color: #666;
  }
}

.textAreaMonitoring{
  width:100%;
  height: 100px;
}



.input--style-4::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666;
}

.input--style-4:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  opacity: 1;
}

.input--style-4::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  opacity: 1;
}

.input--style-4:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
}

.input--style-4:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666;
}
