.appFooterContainer{
    min-height: 25px;
    z-index: 998;
    clear: both;
    position: relative;
    padding: 4px;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-around;
    span {
        padding:2px;
    }
    .footerVersion {
        color: rgba(255,255,255,0.7);
    }
    .footerCopyright {
        opacity: 0.7;
    }
}


@media screen and (max-width: 600px) {
    .appFooterContainer{
        clear: both;
        position: relative;
        //height: 90px;
        //margin-top: -90px;
    }
}

