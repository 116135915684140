.bgRolesList{
  background-color:white;
  width: 100% !important;
}

.bgRolesListGray{
  background-color:rgba(0,0,0,0.1);
  width: 100% !important;
}

.bgPermList{
  background-color:white;
}

.bgPermListGray{
  background-color:rgba(0,0,0,0.05);
}

.bgPermListGray:hover{
  background-color:rgba(0,0,0,0.0);
}

.bgBlockPermListGray{
  background-color:rgba(0,0,0,0.15);
}

.rolesCont{
  background-color: rgba(255,255,255,0.9);
  border-radius:5px 5px 0px 0px;
}

.gridPerm{
  border-radius: 5px;
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0,0,0,.075);
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
  th{
    font-weight: 800;
  }
  td{
    text-align: left;

  }
  tbody {
    tr {
      cursor: default;
      td
      {

        svg{
          cursor: pointer;
        }
      }
    }

    &.no-result {
      padding: 2em;
      text-align: center;
    }
  }


}


.bgRolesList:hover{
  cursor: pointer;
}

.bgRolesListGray:hover{
  cursor: pointer;
}
.correctPass {
  list-style: none;
  color: green !important;
}
.correctPass:before{
  position: relative;
  left: -15px;
  content: '✓' !important;
}
.wrongPass{
  list-style: none;
  color: red ;
}

.wrongPass:before{
  position: relative;
  left: -15px;
  content: "✖";
}

.permDescription{
  margin-top: 0px;
  margin-bottom: 0px !important ;
  font-style: italic;
  color: dimgrey;
}
.permTitle{
  margin-bottom: 0px !important ;
}

.compactPermGrid{
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.alignItem{
  text-align: right;
}

@media screen and (max-width: 900px) {
  .alignItem{
    text-align: left;
  }
}

