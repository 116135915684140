
.gridBtnAddPlace{
  width:calc(100% + 0px);
}

.gridBtnAddPlace2{
  width:calc(100% + 0px);
}

.fullWidthGrid{
  width:100%;
}

.table-responsive{
  border-radius: 5px !important;
}

.gridClinical{

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
  th{
    font-weight: 800;
  }
  td{
    text-align: left;
    //text-transform: lowercase;
  }
  tbody {
    tr {
      cursor: default;
      td
      {
        //text-transform: lowercase;
        svg{
          cursor: pointer;
        }
      }
    }
    &.no-result {
      padding: 2em;
      text-align: center;
    }
  }
  tbody tr:hover{
    cursor: pointer;
  }
}

.calciumIntakeGrid{
  .table td, .table th {
    padding: 5px !important;
    vertical-align: top;
  }
}


