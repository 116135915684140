//gradient basic login
$dark-blue: #222732;
$violet: #7B569F;

.modal-container{
  .modal-content {
    background-color: $dark-blue;
    color: white;
  }
  .modal-header {
    border-bottom: none;
  }
}

.gradient-violet--bg {
  background-image: radial-gradient(circle at top left, #ae95c6, #765298 50%); }


@mixin gradient {
  background: rgb(123,86,159);
  background: linear-gradient(120deg, rgba(123,86,159,0) 0%, rgba(123,86,159,0.25) 20%, rgba(34,39,50,0.6) 30%, $dark-blue 70%, $dark-blue 100%);
}

@mixin gradientBefore {
  content: '';
  display: block;
  height: 500px;
  width: 500px;
  position: absolute;
  background-image:
          radial-gradient(
                          circle at top right,
                          rgba(123,86,159,0.3),
                          rgba(123,86,159,0) 70%
          );
}

.radial-violet--before {
  &::before {
    content: "";
    display: block;
    height: 1000px;
    width: 80%;
    max-width: 650px;
    position: absolute;
    background-image:
            radial-gradient(
                            circle at center left,
                            rgba(123,86,159,0.45),
                            rgba(123,86,159,0) 60%
            );
  }
}
.gradient-before {
  &::before {
    @include gradientBefore;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.gradient {
  &--bg {
    @include gradient;
  }
}

.gradient-violet {
  &--bg {
    background-image:
            radial-gradient(
                            circle at top left,
                            lighten($violet,20),
                            darken($violet,2) 50%
            );
  }
}

