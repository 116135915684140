
:root {
  //roles table
  //$appTableRoleBorder:1px solid var(--lightColor);
  --appTableClinicalHeaderBGColorStriped:rgba(0,0,0,0.1);
  --appTableClinicalHeaderBGColorSubHeader:rgba(0, 0, 0, 0.15);
  //Lyosis special color opacity
  --multiValueBG2:hsl(0, 0%, 90%);
  --lightColor:  rgba(143,177,203,0.4);
  --lightColorLight: rgba(143,177,203,0.4);
  --lightColorHeavy:  rgba(143,177,203,0.8);
  --mediumColor:  #17949A;
  --saveBtnColor:  #17949A;
  --mediumColorLight:  rgba(23,148,154,0.8);
  --heavyColor:  rgba(10, 78, 140,1);
  --heavyColorPagi:  rgba(10, 78, 140,1);
  --heavyColorLight:  rgba(10, 78, 140,0.8);
  --white:rgb(255,255,255);
  --borderAll:rgba(195,195,195,0.2);
  --checkColor:rgba(10, 78, 140,1);
  --activeInnerRadio:rgba(10, 78, 140,1);
  --hiddenEdition:#C5C6C7;
  --hiddenEditionInput:#C5C6C7;
  --boneBread:#f1f1f1;

  --letterbg:rgb(255,255,255);
  --letterColor:black;

  //variables
  --appBackgroundColor: rgb(255,255,255);
  --infoTxt:#FFC18E;
  --appBackgroundColorDashboard: var(--mediumColor);
  --appHrTitleBGColor: var(--heavyColor);
  --appHrSubTitleBGColor: var(--mediumColor);
  --appHrSectionBGColor: var(--mediumColor);
  --appInputTitleColor: var(--mediumColor);
  --appInputUnitColor: var(--mediumColor);
  --appLabelTitleColor: var(--mediumColor);
  --appInputLabelColor: var(--mediumColor);
  --appInputLabelValueColor: var(--heavyColor);
  --appSectionTitleColor: var(--heavyColor);
  --appSubSectionTitleColor: var(--heavyColor);
  --appHeaderIcoColor: var(--heavyColor);
  --inputFocus:var(--mediumColor);
  --inputValue:var(--heavyColor);
  --inputBorder:var(--heavyColor);
  --addElementBtnModalColor:var(--heavyColor);
  --addElementIcoModalColor:var(--white);
  --page404Or403Bg:var(--white);
  --page404Or403TxtColor:var(--heavyColor);
  --infoTxtHover:var(--mediumColor);

  //mandatory border
  --mandatoryBorderWarning:#FFC18E;
  --mandatoryBorderError:red;

  //error text
  --errorTxt:rgb(255,0,0);

  //dashboard dots
  --greenTask: #2D8632;
  --redTask: #AA4139;

  //button
  --appDeleteBtnBGColor: rgb(255,0,0);
  --appDeleteBtnBGColorHover: rgb(255,100,0);
  --appDiscardBtnBGColor: #C2C8CC;
  --appDeleteTextColor: var(--white);
  --appUpdateBtnBGColor: var(--mediumColor);
  --appUpdateBtnBGColorHover: var(--mediumColorLight);
  --appSaveBtnBGColor: var(--heavyColor);
  --appSaveBtnBGColorHover: var(--heavyColorLight);
  --appDiscardBtnBGColorHover: var(--lightColor);
  --appUpdateTextColor: var(--white);
  --appCloseBtnColor: var(--heavyColor);
  --appCloseBtnColorHover: var(--mediumColor);
  --loginPageConnect: var(--heavyColor);
  --loginPageConnectBg: var(--mediumColor);
  --addPatientBorder: var(--heavyColor);
  --addPatientTxt: var(--heavyColor);
  --addPatientHover: var(--lightColor);
  --importBtn:var(--heavyColor);
  --importBtnHover:var(--mediumColor);
  --importBtnTxt:var(--white);

  //input
  --inputBg:#fafafa;

  //form input (import)
  --inputFileBg:var(--heavyColor);
  --inputFileBgHover:var(--heavyColorLight);

  //selected
  --selected:var(--heavyColor);

  //dropdown menu
  --dropDownMenuBg:var(--heavyColor);
  --dropDownTxt:var(--white);
  --dropDownIco:var(--white);
  // Sidebar var
  --appSidebarBGColor: var(--heavyColor);
  --appSidebarIcoColor: var(--white);
  --appSidebarIcoColorHover: var(--mediumColor);
  --appSidebarLv1Color: var(--white);
  --appSidebarLv2Color: var(--white);
  --appSidebarCloseBtnColor: var(--white);
  --appSidebarTxtLv1ColorHover: var(--white);
  --appSidebarTxtLv2ColorHover: var(--white);
  --appSidebarTxtLv1Color: var(--white);
  --appSidebarLv1SelectedColor: rgba(0,0,0,0.2);
  --appSidebarLv2SelectedColor: rgba(0,0,0,0.2);
  --appSidebarBGColorHover: rgba(0,0,0,0.2);

  //navbar var
  --appNavbarBGColor: var(--heavyColor);
  --appNavbarIcoColor: var(--white);
  --appNavbarIcoColorHover: rgba(0,0,0,0.5);
  --appNavbarUlTitleColor: var(--lightColor);
  --appNavbarUlValueColor: var(--white);
  --appNavbarTxtColor: var(--white);

  //Modal var
  --appModalBGColor: var(--white);
  --appModalTxtColor: var(--heavyColor);
  --dropZoneP:var(--white);

  //table
  --highlightTableCol:var(--lightColorLight);
  --bgRolesListhover:var(--lightColor);
  //info calcium table
  --infoCalciumTableBg:var(--white);
  --infoCalciumTableTxt:var(--heavyColor);

  //clinical
  --appTableClinicalHeaderBGColor:rgba(235,243,247,0.2);
  --appTableClinicalHeaderTxtColor: var(--heavyColor);
  --appTableClinicalBGColor: rgba(235,243,247,0.2);
  --appTableClinicalTxtColor: var(--heavyColor);
  --appTablehover: var(--lightColor);
  --appTableClinicalBorder:var(--lightColor);

  //dashboard
  --appDashboardIcoColor: var(--heavyColor);
  --appDashboardIcoColorHover: var(--mediumColor);
  --appDashboardTxtColor: var(--mediumColor);
  --appDashboardLabelColor: var(--mediumColor);
  --headerTitleColor:var(--white);
  //table
  --appTableDashboardHeaderBGColor: var(--white);
  --appTableDashboardHeaderTxtColor: var(--heavyColor);
  --appTableDashboardBGColor: var(--white);
  --appTableDashboardTxtColor: var(--heavyColor);
  --appTableDashboardIcoSelectedColor: var(--heavyColor);
  --appTableDashboardIcoColor: var(--mediumColor);
  --appTableDashboardHoverBg: var(--lightColor);
  --appTableDashboardHoverTxt: var(--heavyColor);

  //hour header
  --appDashboardHourHeaderBGColor: var(--heavyColor);
  --appDashboardHourHeaderTXTColor: var(--white);

  //spinner
  --appSpinnerColor:var(--heavyColor);

  //kpi
  --appKpiTxtColor:var(--heavyColor);
  --lineChart:var(--mediumColor);

  //Letter
  --appLetterTitleTxtColor:var(--heavyColor);
  --appLetterTitleIcoColor:var(--heavyColor);
  --appLetterCreateLetterColor:var(--heavyColor);
  --appLetterCreateLetterColorHover:var(--mediumColor);
  --appLetterListColor:var(--heavyColor);
  --appLetterListIcoColor:var(--mediumColor);
  --appLetterListIcoColorHover:var(--heavyColor);
  --letterListBorder:var(--heavyColor);

  //footer
  --appFooterBGColor: var(--heavyColor);
  --appFooterTxtColor: var(--white);

  --loginTxtColor:#0a4e8c !important;
  --loginTInputBorder:var(--heavyColor);
  --loginTInputFocus:var(--mediumColor);
  --loginTInputFocusBg:var(--lightColor);

  --multiValueBG:hsl(0, 0%, 90%);
  --multiValueColor:var(--heavyColor);

  --ckToolbarBgvar:#fafafa;
  --ckToolbarColor:#333;
  --ckToolbarBorder:#c4c4c4;
  --ckToolbarSelected:#dedede;

  //main btn
  --btnColor1: #17949A;
  --btnColor1Hover: #016368;
  --btnColor2: #2065A3;
  --btnColor2Hover: #043057;
  --btnColor3: #073F72;
  --btnColor3Hover: #043057;
  --btnColor4: #043057;
  --btnColor4Hover: #00172C;
  --btnColorDanger: #D90000;
  --btnColorDangerHover: #FF0000;
//
  --advancedSearchBGbuttonGr:#e9ecef;

  //datepicker
  --datepickerBG:#fff;
  --datepickerColor:#000;
  --datepickerHeaderBG:#f0f0f0;
  --datepickerColorDisable:#ccc;
}

//*************** Dark mode ******************//

[data-theme='dark'] {
  --appBg:rgba(60,64,67,1);
  --multiValueBG2:rgba(195,195,195,0.5);
  --select:rgba(195,195,195,0.5);
  --appNav:rgba(78,83,87,1);
  --tableBg:rgba(60,64,67,0.8);
  --text:rgba(195,195,195,1);
  --label:rgba(195,195,195,0.8);
  --hoverColor:rgba(255, 255, 255, 0.1);
  --borderAll:rgba(195,195,195,0.2);
  --checkColor:rgba(195,195,195,1);
  --activeInnerRadio:rgba(195,195,195,1);
  --letterbg:rgba(78,83,87,1);
  --letterColor:rgba(195,195,195,1);
  --heavyColorLight:rgba(60,64,67,1);
  --heavyColorPagi:  rgba(195,195,195,1);
  --mediumColor:rgba(195,195,195,0.5);
  --saveBtnColor:rgba(100,100,100);
  --heavyColor:rgba(65,75,75,1);
  --white:rgba(195,195,195,1);
  --hiddenEdition:#767B7F;
  --hiddenEditionInput:#9FA2A4;
  --boneBread:rgba(78,83,87,1);

   //variables
  --appBackgroundColor: var(--appBg);
  --infoTxt:#FFC18E;
  --appBackgroundColorDashboard: var(--appBg);
  --appHrTitleBGColor: var(--text);
  --appHrSubTitleBGColor: var(--label);
  --appHrSectionBGColor: var(--label);
  --appInputTitleColor: var(--text);
  --appInputUnitColor: var(--text);
  --appLabelTitleColor: var(--label);
  --appInputLabelColor: var(--label);
  --appInputLabelValueColor: var(--label);
  --appSectionTitleColor: var(--text);
  --appSubSectionTitleColor: var(--text);
  --appHeaderIcoColor: var(--text);
  --inputFocus:var(--label);
  --inputValue:var(--text);
  --inputBorder:var(--text);
  --addElementBtnModalColor:var(--appBg);
  --addElementIcoModalColor:var(--white);
  --page404Or403Bg:var(--appBg);
  --page404Or403TxtColor:var(--text);
  --infoTxtHover:var(--text);

  //mandatory border
  --mandatoryBorderWarning:#FFC18E;
  --mandatoryBorderError:red;

  //error text
  --errorTxt:rgb(255,0,0);

  //dashboard dots
  --greenTask: #2D8632;
  --redTask: #AA4139;

  //button
  --appDeleteBtnBGColor: rgb(255,0,0);
  --appDeleteBtnBGColorHover: rgb(255,100,0);
  --appDiscardBtnBGColor: #C2C8CC;
  --appDeleteTextColor: var(--white);
  --appUpdateBtnBGColor: var(--mediumColor);
  --appUpdateBtnBGColorHover: var(--mediumColorLight);
  --appSaveBtnBGColor: var(--appNav);
  --appSaveBtnBGColorHover: var(--heavyColorLight);
  --appDiscardBtnBGColorHover: var(--lightColor);
  --appUpdateTextColor: var(--white);
  --appCloseBtnColor: var(--heavyColor);
  --appCloseBtnColorHover: var(--mediumColor);
  --loginPageConnect: var(--heavyColor);
  --loginPageConnectBg: var(--mediumColor);
  --addPatientBorder: var(--text);
  --addPatientTxt: var(--text);
  --addPatientHover: var(--hoverColor);
  --importBtn:var(--heavyColor);
  --importBtnHover:var(--mediumColor);
  --importBtnTxt:var(--white);

  //input
  --inputBg:var(--appNav);

  //form input (import)
  --inputFileBg:var(--appNav);
  --inputFileBgHover:var(--appBg);

  //selected
  --selected:var(--select);

  //dropdown menu
  --dropDownMenuBg:var(--appNav);
  --dropDownTxt:var(--text);
  --dropDownIco:var(--text);

  // Sidebar var
  --appSidebarBGColor: var(--appNav);
  --appSidebarIcoColor: var(--text);
  --appSidebarIcoColorHover: var(--text);
  --appSidebarLv1Color: var(--text);
  --appSidebarLv2Color: var(--text);
  --appSidebarCloseBtnColor: var(--text);
  --appSidebarTxtLv1ColorHover: var(--text);
  --appSidebarTxtLv2ColorHover: var(--text);
  --appSidebarTxtLv1Color: var(--text);
  --appSidebarLv1SelectedColor: rgba(0,0,0,0.2);
  --appSidebarLv2SelectedColor: rgba(0,0,0,0.2);
  --appSidebarBGColorHover: rgba(0,0,0,0.2);

  //navbar var
  --appNavbarBGColor: var(--appNav);
  --appNavbarIcoColor: var(--text);
  --appNavbarIcoColorHover: rgba(0,0,0,0.5);
  --appNavbarUlTitleColor: var(--text);
  --appNavbarUlValueColor: var(--text);
  --appNavbarTxtColor: var(--text);

  //Modal var
  --appModalBGColor: var(--appBg);
  --appModalTxtColor: var(--text);
  --dropZoneP:var(--white);

  //table
  --highlightTableCol:var(--hoverColor);
  --bgRolesListhover:var(--hoverColor);
  //info calcium table
  --infoCalciumTableBg:var(--appNav);
  --infoCalciumTableTxt:var(--text);

  //clinical
  --appTableClinicalHeaderBGColor:var(--appNav);
  --appTableClinicalBGColor: var(--appNav);
  --appTableClinicalHeaderTxtColor: var(--text);
  --appTableClinicalTxtColor: var(--text);
  --appTablehover: var(--hoverColor);
  --appTableClinicalBorder:var(--appNav);

  //dashboard
  --headerTitleColor:var(--text);
  --appDashboardIcoColor: var(--text);
  --appDashboardIcoColorHover: var(--label);
  --appDashboardTxtColor: var(--label);
  --appDashboardLabelColor: var(--label);

    //table
  --appTableDashboardHeaderBGColor: var(--appNav);
  --appTableDashboardHeaderTxtColor: var(--text);
  --appTableDashboardBGColor: var(--appNav);
  --appTableDashboardTxtColor: var(--text);
  --appTableDashboardIcoSelectedColor:var(--text) ;
  --appTableDashboardIcoColor: var(--appBg);
  --appTableDashboardHoverBg: var(--label);
  --appTableDashboardHoverTxt: var(--label);

  //hour header
  --appDashboardHourHeaderBGColor: var(--appNav);
  --appDashboardHourHeaderTXTColor: var(--text);

  //spinner
  --appSpinnerColor:var(--text);

  //kpi
  --appKpiTxtColor:var(--label);
  --lineChart:var(--appNav);

  //Letter
  --appLetterTitleTxtColor:var(--text);
  --appLetterTitleIcoColor:var(--text);
  --appLetterCreateLetterColor:var(--text);
  --appLetterCreateLetterColorHover:var(--label);
  --appLetterListColor:var(--text);
  --appLetterListIcoColor:var(--text);
  --appLetterListIcoColorHover:var(--label);
  --letterListBorder:var(--text);

  //footer
  --appFooterBGColor: var(--appNav);
  --appFooterTxtColor: var(--text);

  //login text
  --loginTxtColor:#0d6efd !important;
  --loginTInputBorder:var(--text);
  --loginTInputFocus:var(--label);
  --loginTInputFocusBg:var(--label);

  --multiValueBG:var(--appBg);
  --multiValueColor:var(--text);

  --ckToolbarBgvar:var(--appNav);
  --ckToolbarColor:var(--text);
  --ckToolbarBorder:var(--appBg);
  --ckToolbarSelected:var(--lightColorLight);

  //main btn
  --btnColor1: #63666F;
  --btnColor1Hover: #101217;
  --btnColor2: #63666F;
  --btnColor2Hover: #101217;
  --btnColor3: #101217;
  --btnColor3Hover: #0C0F18;
  --btnColor4: #0C0F18;
  --btnColor4Hover: #060912;
//
  --advancedSearchBGbuttonGr:#63666F;

  //datepicker
  --datepickerBG:rgba(78,83,87,1);
  --datepickerColor:rgba(195,195,195,1);
  --datepickerColorDisable:#989898;
  --datepickerHeaderBG:rgba(78,83,87,1);
}
$advancedSearchBGbuttonGr:var(--advancedSearchBGbuttonGr);
$heavyColor:var(--heavyColor);
//input
$inputBg:var(--inputBg);

//letter modal
$letterBg:var(--letterbg);
$letterColor:var(--letterColor);

//form input (import)
$inputFileBg:var(--inputFileBg);
$inputFileBgHover:var(--inputFileBgHover);

//sidebar font size
$sideTitleFontSize:21px;
$sideElemFontSize:18px;

//cookie
$btnCookieTxt:var(--heavyColor);
$btnCookieBg:var(--white);

//selected
$selected:var(--selected);

//save btn
$saveBtnBg:var(--saveBtnColor);
$saveBtnhoverBg:var(--mediumColorLight);
$saveBtnhovertxt:var(--white);
$saveBtntxt:var(--white);
$saveBtnBgFocus:var(--mediumColor);
$saveBtnFocusBorder:var(--mediumColor);
$saveBtnFocusHover:var(--mediumColor);
$saveBtnActive:var(--mediumColor);
$saveBtnActiveBorder:var(--mediumColor);

//login text
$loginTxtColor:var(--loginTxtColor);
$loginTInputBorder:var(--loginTInputBorder);
$loginTInputFocus:var(--loginTInputFocus);
$loginTInputFocusBg:var(--loginTInputFocusBg);

// Variables
$appBackgroundColorDashboard: var(--appBackgroundColorDashboard);
$appHrTitleBGColor: var(--appHrTitleBGColor);
$appHrSubTitleBGColor: var(--appHrSubTitleBGColor);
$appHrSectionBGColor: var(--appHrSectionBGColor);
$appInputTitleColor: var(--appInputTitleColor);
$appInputUnitColor: var(--appInputUnitColor);
$appLabelTitleColor: var(--appLabelTitleColor);
$appInputLabelColor: var(--appInputLabelColor);
$appInputLabelValueColor: var(--appInputLabelValueColor);
$appSectionTitleColor: var(--appSectionTitleColor);
$appSubSectionTitleColor: var(--appSubSectionTitleColor);
$appHeaderIcoColor: var(--appHeaderIcoColor);
$inputFocus:var(--inputFocus);
$inputValue:var(--inputValue);
$inputBorder:var(--inputBorder);
$addElementBtnModalColor:var(--addElementBtnModalColor);
$addElementIcoModalColor:var(--addElementIcoModalColor);
$page404Or403Bg:var(--page404Or403Bg);
$page404Or403TxtColor:var(--page404Or403TxtColor);
$infoTxtHover:var(--infoTxtHover);

//dropdown menu
$dropDownMenuBg:var(--dropDownMenuBg);
$dropDownTxt:var(--dropDownTxt);
$dropDownIco:var(--dropDownIco);

// Sidebar var
$appSidebarBGColor: var(--appSidebarBGColor);
$appSidebarIcoColor: var(--appSidebarIcoColor);
$appSidebarIcoColorHover: var(--appSidebarIcoColorHover);
$appSidebarLv1Color: var(--appSidebarLv1Color);
$appSidebarLv2Color: var(--appSidebarLv2Color);
$appSidebarCloseBtnColor: var(--appSidebarCloseBtnColor);
$appSidebarTxtLv1ColorHover: var(--appSidebarTxtLv1ColorHover);
$appSidebarTxtLv2ColorHover: var(--appSidebarTxtLv2ColorHover);
$appSidebarTxtLv1Color: var(--appSidebarTxtLv1Color);
$appSidebarLv1SelectedColor: var(--appSidebarLv1SelectedColor);
$appSidebarLv2SelectedColor: var(--appSidebarLv2SelectedColor);
$appSidebarBGColorHover: var(--appSidebarBGColorHover);

//navbar var
$appNavbarBGColor: var(--appNavbarBGColor);
$appNavbarIcoColor: var(--appNavbarIcoColor);
$appNavbarIcoColorHover: var(--appNavbarIcoColorHover);
$appNavbarUlTitleColor: var(--appNavbarUlTitleColor);
$appNavbarUlValueColor: var(--appNavbarUlValueColor);
$appNavbarTxtColor: var(--appNavbarTxtColor);

//Modal var
$appModalBGColor: var(--appModalBGColor);
$appModalTxtColor: var(--appModalTxtColor);

//button
$appDeleteBtnBGColor: var(--appDeleteBtnBGColor);
$appDeleteBtnBGColorHover: var(--appDeleteBtnBGColorHover);
$appDeleteTextColor: var(--appDeleteTextColor);
$appUpdateBtnBGColor: var(--appUpdateBtnBGColor);
$appUpdateBtnBGColorHover: var(--appUpdateBtnBGColorHover);
$appSaveBtnBGColor: var(--appSaveBtnBGColor);
$appSaveBtnBGColorHover: var(--appSaveBtnBGColorHover);
$appDiscardBtnBGColor: var(--appDiscardBtnBGColor);
$appDiscardBtnBGColorHover: var(--appDiscardBtnBGColorHover);
$appUpdateTextColor: var(--appUpdateTextColor);
$appCloseBtnColor: var(--appCloseBtnColor);
$appCloseBtnColorHover: var(--appCloseBtnColorHover);
$loginPageConnect: var(--loginPageConnect);
$loginPageConnectBg: var(--loginPageConnectBg);
$addPatientBorder: var(-addPatientBorder);
$addPatientTxt: var(--addPatientTxt);
$addPatientHover: var(--addPatientHover);
$importBtn:var(--importBtn);
$importBtnHover:var(--importBtnHover);
$importBtnTxt:var(--importBtnTxt);


//table
$highlightTableCol:var(--highlightTableCol);
$bgRolesListhover:var(--bgRolesListhover);
//info calcium table
$infoCalciumTableBg:var(--infoCalciumTableBg);
$infoCalciumTableTxt:var(--infoCalciumTableTxt);

  //clinical
$appTableClinicalHeaderBGColor: var(--appTableClinicalHeaderBGColor);
$appTableClinicalHeaderTxtColor: var(--appTableClinicalHeaderTxtColor);
$appTableClinicalBGColor: var(--appTableClinicalBGColor);
$appTableClinicalTxtColor: var(--appTableClinicalTxtColor);
$appTablehover: var(--appTablehover);
$appTableClinicalBorder:var(--appTableClinicalBorder);

//dashboard
$headerTitleColor:var(--headerTitleColor);
$appDashboardIcoColor: var(--appDashboardIcoColor);
$appDashboardIcoColorHover: var(--appDashboardIcoColorHover);
$appDashboardTxtColor: var(--appDashboardTxtColor);
$appDashboardLabelColor: var(--appDashboardLabelColor);
  //table
$appTableDashboardHeaderBGColor: var(--appTableDashboardHeaderBGColor);
$appTableDashboardHeaderTxtColor: var(--appTableDashboardHeaderTxtColor);
$appTableDashboardBGColor: var(--appTableDashboardBGColor);
$appTableDashboardTxtColor: var(--appTableDashboardTxtColor);
$appTableDashboardIcoSelectedColor: var(--appTableDashboardIcoSelectedColor);
$appTableDashboardIcoColor: var(--appTableDashboardIcoColor);
$appTableDashboardHoverBg: var(--appTableDashboardHoverBg);
$appTableDashboardHoverTxt: var(--appTableDashboardHoverTxt);

  //hour header
$appDashboardHourHeaderBGColor: var(--appDashboardHourHeaderBGColor);
$appDashboardHourHeaderTXTColor: var(--appDashboardHourHeaderTXTColor);

//spinner
$appSpinnerColor:var(--appSpinnerColor);

//kpi
$appKpiTxtColor:var(--appKpiTxtColor);

//Letter
$appLetterTitleTxtColor:var(--appLetterTitleTxtColor);
$appLetterTitleIcoColor:var(--appLetterTitleIcoColor);
$appLetterCreateLetterColor:var(--appLetterCreateLetterColor);
$appLetterCreateLetterColorHover:var(--appLetterCreateLetterColorHover);
$appLetterListColor:var(--appLetterListColor);
$appLetterListIcoColor:var(--appLetterListIcoColor);
$appLetterListIcoColorHover:var(--appLetterListIcoColorHover);
$letterListBorder:var(--letterListBorder);

//footer
$appFooterBGColor: var(--appFooterBGColor);
$appFooterTxtColor: var(--appFooterTxtColor);

//dashboard dots
$greenTask: var(--greenTask);
$redTask: var(--redTask);
$purpleTask: var(--heavyColor);

$multiValueBG:var(--multiValueBG);
$multiValueColor:var(--multiValueColor);

//ck editor toolbar
$ckToolbarBg: var(--ckToolbarBg);
$ckToolbarColor: var(--ckToolbarColor);


//react select
//!!!!!maybe change this!!!!!

[class$="-control"] {
  background-color: $inputBg !important;
  //border-color: var(--borderAll) !important;
}
[class$="-menu"] {
  background-color: $inputBg !important;
  color: $inputValue !important;
  //border-color: var(--borderAll) !important;
}

[class$="-singleValue"] {
  color: $inputValue !important;
}

[class$="-multiValue"] {
  background-color: $multiValueBG !important;
  color:$multiValueColor ;
}
[id^=-option]{
  &:hover{
    background-color: $selected !important;
    color: var(--white)!important;
  }
}

.css-3dp776-option{
  background-color: $selected !important;
  color: var(--white)!important;
}
.css-1eekjfk-option{
  background-color: $selected !important;
}
.css-19w11df-option{
  background-color: $appTablehover !important;
}

.css-ylblyp-option{
  background-color: $selected !important;
  color: var(--white)!important;
}
.css-v8dneu-option{
  background-color: var(--appTablehover) !important;
}

.css-1osf2f-option{
  background-color: $selected !important;
  color: white!important;
}

.css-41s9lb-control{
  border: 4px solid #ffc18e!important;
}

.css-yk16xz-control{
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
}
.css-1pahdxg-control{
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
}
.css-5duvv3-control{
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
}
.css-1vp9nu5-control{
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
}
.css-nhgxol-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  padding: 0px;
  color: black;
}

.css-uwnxh3-control{
  background-color: $inputBg !important;
  border-color: var(--borderAll) !important;
  &:hover{
    border-color: var(--borderAll) !important;
  }
  &:focus{
    border-color: var(--borderAll) !important;
  }
  box-shadow: $inputBg !important;;
}

.css-1443i6g-singleValue{
  color: $inputValue !important;
}
.css-hqr2jt-control{
  border:4px solid var(--mandatoryBorderWarning) !important;
}
.css-1l42yjb-control{
  border:4px solid var(--mandatoryBorderError) !important;
}

.css-kzvie0-control{
  border:4px solid var(--mandatoryBorderError) !important;
}
.css-1caxtnd-control{
  border:4px solid var(--mandatoryBorderWarning) !important;
}
.css-9gakcf-option{
  background-color: $selected !important;
}
.textOptionBlackSpecial{
  color: $inputValue !important;
}
.textOptionBlack{
  color: $inputValue !important;
}
.css-12jo7m5{
  color: $inputValue !important;
}


.css-1n7v3ny-option {
  background-color: $appTablehover!important;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}


//load font
@font-face {
  font-family: 'SemplicitaPro';
  src: url(../Fonts/SemplicitaPro/SemplicitaPro.otf) format('truetype');
}

body, a, .btn-primary, .btn-secondary, .btn-link.white, h1, .h1, h2, .h2, h4, .h4, section.privacy h3, .condensed,
.title, .title span, .title small, form input, .contact-list li a, section.privacy h3, .schema-wrapper strong span,
.sidebg, .sidebg a,* {
  font-family: 'SemplicitaPro', sans-serif !important;
}


//dashboard dots
.greenTask{
  color: $greenTask;
}

.redTask{
  color: $redTask;
}

.purpleTask{
  color:$purpleTask;
}

//cookie
button#rcc-confirm-button{
  color: $btnCookieTxt !important;
  background-color:$btnCookieBg !important;
}

span#cookit-message>a{
  color:$btnCookieBg;
  &:hover{
    color:$btnCookieTxt ;
  }
}



//login page connect btn
span.btn-primary{
  background-color: $loginPageConnect;
  box-shadow: 2px 23px 27px 11px rgba(0,0,0,0.2);
  &:hover{
    background-color:$loginPageConnectBg ;
  }
}

//login page gradient
section.homeBasic .gradient-overlay {
  background: rgba(255, 255, 255, 1);
  background: linear-gradient(120deg, rgba(10, 78, 140, 0.7) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 70%, rgba(10, 78, 140, 0.7) 100%, rgba(10, 78, 140, 0.7) 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

section.homeBasic {
  background-color: rgba(255, 255, 255, 1);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
//login text color
div.content>h1{
  color: $loginTxtColor !important;
}

div.content>h1>span,small{
  color: $loginTxtColor !important;
  opacity: 1 !important;
}

div.content>p,strong{
  color: $loginTxtColor !important;
}

div.descri>p,strong{
  color: var(--appSubSectionTitleColor) !important;
}

div.ck.ck-content * {
  color: black !important;
}

div#connection>div>form>input {
  background-color: white;
  border: 2px solid $loginTInputBorder;
  border-radius: 3px;
  color: $loginTInputBorder;
  &:focus{
    color:$loginTInputFocus !important;
    background-color: $loginTInputFocusBg;
  }
}



/*app*/

/*app background(admin,clinical,...)*/
.appBackGroundColor {
  background-color: var(--appBackgroundColor);
}
.contextContainer{
  background-color: var(--appBackgroundColor);
}


/*hr*/
  /*title hr*/
    hr.titleHr{
      background-color: $appHrTitleBGColor;
    }
      /*sub title hr*/
    hr.subTitleHr{
      background-color: $appHrSubTitleBGColor;
    }
      /*section hr*/
    hr.inSection{
      background-color: $appHrSectionBGColor;
    }
    hr.inSectionSpecial{
      background-color: $appHrSectionBGColor;
    }
    hr{
      background-color: $appHrSectionBGColor;
    }

/*radio/checkbox subtitle*/
h6,h6.inlineRadioTitle {
  text-align: left;
  font-weight: bold;
  min-height: 20px;
  margin-top: 10px;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: $appInputTitleColor;
}

/*unit after input*/

.unit {
  color: $appInputUnitColor;
  vertical-align: middle;
  margin-top: 5px;
}

label.maxPainScale{
  color: $appInputUnitColor;
}


/*app label*/
  /*main label*/
  .contextContainer,label{
    color: $appLabelTitleColor;
  }
  /*label with input*/
  .contextContainer,input[type="radio"]+label,input[type="checkbox"]+label,label.form-value-label{
    color: $appInputLabelValueColor;
  }

  /*section title*/
  .contextContainer,h5,h4.font-weight-bold,ol{
    color: $appSubSectionTitleColor;
  }
  li>h5{
   // font-weight: bold;
  }
  h5.textHeaderSize{
    color: var(--white);
  }
  .contextContainer h4 {
    color: $appSectionTitleColor;
  }

  .contextContainer h4 > span> svg{
    color: $appHeaderIcoColor;
  }
  /*Header icon */
  .purple{
    color:$appHeaderIcoColor;
  }

.purple.toggleHeader{
  color:$appHeaderIcoColor !important;
}
.purple.toggleHeader2{
  color:$appHeaderIcoColor !important;
}


/*sidebar*/
  /*sidebar bg*/
    .sideMenuItem>*{
      color: $appSidebarIcoColor;
    }
    .sidebg{
      background-color: $appSidebarBGColor;
      /*icone color*/
      svg{
        color: $appSidebarIcoColor;
      }
      /*lv1 menu color*/
      p,h1,h2,h3,h4,h5{
        color: $appSidebarLv1Color;
      }
      /*lv2 menu color*/
      li{
        color: $appSidebarLv2Color;
      }
      /*close btn color */
      h4.closebtn>svg{
        color:$appSidebarCloseBtnColor;
      }
    }
    .sidebg span.closebtn > svg {
      color: $appSidebarIcoColor;
      &:hover{
        color: $appSidebarIcoColorHover;
      }
    }
  /*lv1 menu selected*/
    .lvl1MenuItem.selected {
      background-color: $appSidebarLv1SelectedColor !important;
      /*lv1 color*/
      p {
        color: $appSidebarTxtLv1Color;
      }
      /*lv1 hover color*/
      &:hover{
        p{
          color: $appSidebarTxtLv1ColorHover;
        }
      }
    }
    .lvl1MenuItem{
      p {
        color: $appSidebarTxtLv1Color;
      }
    /*lv1 hover color*/
    &:hover{
      p{
        color: $appSidebarTxtLv1ColorHover;
      }
    }
  }
.sideMenuItem{
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
}
li.vl.sideMenuItem{
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  padding: 2px 10px 2px 15px;
}
  /*hover sidebar elem*/
  .sideMenuItem:hover{
    padding: 10px 10px 10px 15px;
    background-color: $appSidebarBGColorHover;
    color: $appSidebarTxtLv2ColorHover;
  }
  /*selected lv2*/
  .selected {
    background-color: $appSidebarLv2SelectedColor !important;
  }
  .selectedBlind{
    background-color: $appSidebarLv2SelectedColor !important;
  }


/*input type text*/

.input--style-4{
  cursor:text;
  max-width: 100%!important;
  outline: none;
  font-family: inherit;
  line-height: 35px;
  background: $inputBg;
  -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0 5px;
  color: $inputValue !important;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 5px;
  &:focus {
    color: $inputValue !important;
    background-color:$inputBg ;
  }
  &:hover {
    color: #666;
  }
}
textarea{
  color:$inputValue;
  background-color: $inputBg;
  &:focus {
    color: $inputValue !important;
    background-color: $inputBg;
  }
}
/*input border*/
input[type='checkbox']{
  background-color: $inputBg;//var(--appBackgroundColor)!important;
  border: 1px solid $inputBorder;
  --active-inner: var(--checkColor)  !important;
}
input[type='checkbox']:focus {
  box-shadow: var(--appBackgroundColor) !important;
  color: #666 !important;
  background-color: var(--appBackgroundColor) !important;
}
.form-check-input:checked[type=checkbox] {
}
.form-check-input:checked {
  background-color: var(--appBackgroundColor);
  border-color: $inputBorder;
}
input[type='radio']{
  background-color:var(--appBackgroundColor)!important;
  border: 1px solid $inputBorder;
  --active-inner: var(--activeInnerRadio) !important;
}
.noWhiteCircle input[type='radio'] {
  --active-inner: var(--activeInnerRadio) !important;
}
/*input type radio*/
/*input type checkbox*/

/*navbar*/
  /*navbar background*/
    nav.bgAppNavbar{
      background-color: $appNavbarBGColor ;
    }

    nav .logoAppBar {
      width: 100%;
    }
    nav .navbar-nav .nav-item a.nav-link{
      color: $appNavbarIcoColor;
      &:hover {
        color: $appNavbarUlValueColor;
        background-color: $appUpdateBtnBGColorHover;
      }
    }
    nav>div.headerHospital{
      color: $appNavbarTxtColor;
    }


  /*navbar link color+hover*/
    .navbar-nav>div.nav-item>a.nav-link>svg.purple  {
      color: $appNavbarIcoColor;
      &:hover{
        color: $appNavbarIcoColorHover;
      }
    }
.navbar-nav>div.nav-link>a>svg.purple  {
  color: $appNavbarIcoColor !important;
  &:hover{
    color: $appNavbarIcoColorHover !important;
  }
}
    .appNavItem{
      color: $appNavbarIcoColor !important;
    }
    .navbar-nav>a.appNavItem,a.purple,span.purple  {
      color: $appNavbarIcoColor !important;
      &:hover{
        color: $appNavbarIcoColorHover !important;
      }
    }

    .blindIco{
      cursor: pointer;
      color: $appNavbarIcoColor !important;
      &:hover{
        color: $appNavbarIcoColorHover !important;
      }

    }

  /*name, bd ,id patient*/
    nav.bgAppNavbar ul li.liNav{
      color: $appNavbarUlTitleColor ;
    }
    span.liNav{
      color:$appNavbarUlTitleColor;
    }
  /*name, bd ,id patient*/
    nav.bgAppNavbar ul{
      color: $appNavbarUlValueColor ;
    }

/*modal*/
    /*modal background*/
      .modal-container .modal-content{
        background-color: $appModalBGColor;
      }
    /*modal text*/
      .modal-container .modal-content  p,.h4{
        color: $appModalTxtColor;
      }
      .modal-body{
        color: $appModalTxtColor;
      }
    /*modal button*/
      /*update btn*/
        button.customModalBtn.btn.btn-warning{
          background-color: $appUpdateBtnBGColor;
          color: $appUpdateTextColor;
          &:hover{
            background-color: $appUpdateBtnBGColorHover;
          }
        }
        .customModalBtn.modalUpdateBtn{
          background-color: $appUpdateBtnBGColor;
          color: $appUpdateTextColor;
          &:hover{
            background-color: $appUpdateBtnBGColorHover;
          }
        }
        .customModalBtn.btn-danger.btn--blue{
          background-color: $appSaveBtnBGColor !important;
          color: $appUpdateTextColor !important;
          &:hover{
            background-color: $appSaveBtnBGColorHover !important;
          }
        }
    /*delete btn*/
    .customModalBtn.modalDeleteBtn{
      background-color: $appDeleteBtnBGColor;
      color: $appDeleteTextColor;
      &:hover{
        background-color: $appDeleteBtnBGColorHover;
      }
    }
    .customModalBtn.btn-danger{
      background-color: $appDeleteBtnBGColor;
      color: $appDeleteTextColor;
      &:hover{
        background-color: $appDeleteBtnBGColorHover;
      }
    }
      /*close btn*/
      .modal-header button.close{
        color: $appCloseBtnColor;
        &:hover{
          color: $appCloseBtnColorHover;
        }
      }
  /*add element btn*/
    .customBtn{
      border-radius: 5px;
      background-color: $addElementBtnModalColor;
      cursor: pointer;
      border: 1px solid white;
      padding: 2px 8px 5px 8px;
      color: $addElementIcoModalColor;
    }

/*table clinical*/
    /*table header*/
    table.gridClinical{
      background-color: $appTableClinicalHeaderBGColor;
      color:$appTableClinicalHeaderTxtColor;
      border:1px solid  $appTableClinicalBorder;
    }
    /*table body*/
    table.gridClinical tbody{
      background-color: $appTableClinicalBGColor;
      color: $appTableClinicalTxtColor;
    }
    .gridClinical tbody tr:hover td {
      background: $appTablehover;
    }
    .gridClinicalSelected {
      background: var(--lightColorHeavy) !important;
    }

    .gridPerm tbody tr:hover td {
      background: $appTablehover;
    }

    .bgPermList{
      background-color:rgba(235, 243, 247, 0.2) !important;
    }
    table.gridPerm{
      color: $appTableClinicalTxtColor;
      border: $appTableClinicalBorder;
    }
    p.permDescription{
      color: $appTableClinicalTxtColor;
      opacity: 0.8;
    }


/*footer*/
  /*footer background*/
  .backgroundfooter{
    background-color: $appFooterBGColor;
  }

  /*footer text color*/
  .backgroundfooter>div>div>div>span{
    color: $appFooterTxtColor;
    .appFooterContainer  span.footerVersion{
      color: $appFooterTxtColor;
    }
    a.btn-link,p{
      color: $appFooterTxtColor;
    }
  }

/*Dashboard*/
/*Dashboard add btn*/
.btnBordered.btn--addPatient{
  border: 1px solid $addPatientBorder;
  color:$addPatientTxt ;
  &:hover{
    color: white;
    background-color: $heavyColor;
  }
}

.btnBordered.btn--search{
  border: 1px solid $addPatientBorder;
  color:$addPatientTxt ;
  &:hover{
    color: white;
    background-color: $heavyColor;
  }
}

  /*dashboard icone*/
  svg.printExportHoverIco {
    color:$appDashboardIcoColor;
    &:hover{
      color: $appDashboardIcoColorHover;
    }
  }

    /*addPatient button*/
  .btnBordered{
    color: #f8f9fa;
    border: 2px solid var(--addPatientBorder);
    background-color: transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  /*patient count text*/
  div.dashboardCont{
    p.patientCount {
      font-size: 0.8rem;
      color: $appDashboardTxtColor;
    }
  }
  @media screen and (max-width: 1300px){
    .patientCount {
      color: $appDashboardTxtColor !important;
    }
  }
  @media screen and (max-width: 1000px){
    .patientCount {
      color: $appDashboardTxtColor !important;
    }
  }

  /*background*/
  div .rootBackground{
    background-color: var(--appBackgroundColor);
  }

  /*dashboard table filters color*/
  div.dashboardCont{
   p,div.form-check>input[type='checkbox'] + label.form-check-label,input[type='radio'] + label.form-check-label{
     color: $appDashboardLabelColor !important;
    }
  }
.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg:var(--appTableClinicalHeaderBGColorStriped);
  color: $appTableClinicalTxtColor;
}
.table-light {
  --bs-table-bg: $appTableClinicalBGColor;
  --bs-table-striped-bg: $appTableClinicalBGColor;
  --bs-table-striped-color: $appTableClinicalBGColor;
  --bs-table-active-bg:$appTableClinicalBGColor;
  --bs-table-active-color: $appTableClinicalBGColor;
  --bs-table-hover-bg: $appTableClinicalBGColor;
  --bs-table-hover-color: $appTableClinicalBGColor;
  color: $appTableClinicalBGColor;
  border-color: $appTableClinicalBGColor;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
   border-style: none;
  border-width: 0;
}

.table>:not(caption)>*>* {
  padding: .5rem .5rem;
  /*background-color: var(--appTableClinicalHeaderBGColorSubHeader);*/
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.minHeightTable,.table table{
  background-color: $appTableDashboardHeaderBGColor;
  color:$appTableDashboardHeaderTxtColor;
}

  /* dashboard table background and text color*/
  .dashboardGrid,.table table{
    background-color: $appTableDashboardHeaderBGColor;
    color:$appTableDashboardHeaderTxtColor;
    border: 1px solid var(--borderAll) !important;
  }
  .item.activeTask, .item:hover {
    background: $highlightTableCol;
    color: $appTableDashboardHoverTxt;
  }
  .selectedDirIcon svg {
    color: $appTableDashboardIcoSelectedColor;
    cursor: pointer;
  }
  .notSelectedDirIcon svg {
    color: $appTableDashboardIcoColor;
    cursor: pointer;
  }
  /*header with hour background*/
  div .bottom-left-info{
    background-color: $appDashboardHourHeaderBGColor;
  }
  /*header with hour text color*/
  div .bottom-left-info>p,h3,div>p.textPSize {
    color: $appDashboardHourHeaderTXTColor;
  }

/*Loader*/
    /*loader background*/
  .loaderContainer{
    background-color: var(--appBackgroundColor);
  }
    /*spinner*/
  .icn-spinner svg {
    color: $appSpinnerColor;
  }

/*kpi cont*/
  div.kpiCont{
    background-color: var(--appBackgroundColor) !important;
  }
    /*kpi title*/
  div.kpiCont{
    h4.text-white{
      color: $appKpiTxtColor!important;
    }
  }
  /*kpi description*/
  .descri{
    div {
    }
    li,p, div{
      color: $appKpiTxtColor;
    }
  }

/*Letters*/
    /*letter title*/
  p.communicationGroupTitle{
    color: $appLetterTitleTxtColor;
    /*icone*/
    span.greyColor{
      color:$appLetterTitleIcoColor;
    }
  }
    /*create letter*/
  p.createLetter{
    color: $appLetterCreateLetterColor;
    &:hover{
      color:$appLetterCreateLetterColorHover;
    }
  }
    /*letter list*/
  .marginLeftLettersList{
    p{
      color:$appLetterListColor
    }
    label>svg{
      color:$appLetterListIcoColor!important;
      &:hover{
        color:$appLetterListIcoColorHover!important;
      }
    }
  }
  /*Letter modal*/
/*.CKEditorContainer{
  background-color: red;
}*/
div.ck.ck-content * {
  font-family: 'Geneva','Tahoma','Verdana','sans-serif' !important;
}
 .modal-container .modal-content .letterInModal > .row{
    background-color: $letterBg;
   *{
      color: $letterColor !important;
    }
  }
    /*letter modal btn */
  .modal-footer{
    /*discard btn*/
    button.discardBtn.btn.btn-danger{
      background-color: $appDiscardBtnBGColor;
      border: 1px solid $appDiscardBtnBGColor;
      &:hover{
        background-color: $appDiscardBtnBGColorHover;
      }
    }
    /*delete btn*/
    button.deleteBtn.btn.btn-danger{
      background-color: $appDeleteBtnBGColor;
      border:none;
    }
    /*saveAsDraftBtn btn*/
    button.saveAsDraftBtn.btn.btn-danger{
      background-color: $appSaveBtnBGColor!important;
      border:1px solid $appSaveBtnBGColor!important;
      &:hover{
        background-color: $appSaveBtnBGColorHover!important;
      }
    }
    /*finalizeBtn btn*/
    button.finalizeBtn.btn.btn-danger{
      background-color: $appUpdateBtnBGColor!important;
      border:1px solid $appUpdateBtnBGColor!important;
      &:hover{
        background-color: $appUpdateBtnBGColorHover!important;
      }
    }
  }
.letterElemBorder{
  border-left: 2px solid $letterListBorder !important;
  border-bottom: 2px solid $letterListBorder !important;
}

/*Monitoring*/
.containerMonitoring{
  h5{
    color:$appSectionTitleColor!important;
  }
  p{
    color:$appLabelTitleColor!important;
  }
  p.infoCheck{
    color: orange!important;
  }
}

/*page404/403*/

div.global404Page{
  background-color:$page404Or403Bg;
  color: $page404Or403TxtColor;
}

div.global404Page{
  background-color:$page404Or403Bg;
  color: $page404Or403TxtColor;
}

p.text-white{
  color: $appSubSectionTitleColor!important;
}

tr>th.highlightedCol,td.highlightedCol {
  background-color: $highlightTableCol!important;
}


.bgRolesListGray:hover {
  background-color: $bgRolesListhover;
}
.bgRolesList:hover{
  background-color: $bgRolesListhover;
}

.dropdown-menu {
  background-color: $dropDownMenuBg;
}
.dropdown-toggle.nav-link{
  color:$appNavbarIcoColor !important;
  &:hover{
    color:$appNavbarIcoColorHover !important;
    background-color: rgba(10, 78, 140, 0) !important;
  }
}

.dropdown-item {
  color: $dropDownTxt;
  &:hover{
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}
div.dropdown-item{
  color: $dropDownTxt;
  text-decoration: none;
  &:hover{
    background-color: rgba(255, 255, 255, 0.1) !important;
    text-decoration: none;
  }
}
div.dropdown-item>svg{
  color: $dropDownIco;
}

a.appNavItem > svg {
  color:$dropDownIco;
}
a.dropdown-item > svg {
  color:$dropDownIco;
}

.infoText{
  color: var(--infoTxt) !important;
}
label>span.infoText{
  color: var(--infoTxt) ;
}
label>a>span.infoText{
  color: var(--infoTxt);
  &:hover{
    color: $infoTxtHover;
  }
}

.infoCalmodalLink p{
  color: var(--infoTxt) !important;
  &:hover{
    color:$infoTxtHover !important;
  }
}
label.infoCheck{
  color: var(--infoTxt) !important;
}

div.calciumIntakeGrid >table{
  background-color: $infoCalciumTableBg;
  color: $infoCalciumTableTxt;
}


//save btn

div.savebtnStyle>div>button.colorBtn{
  background-color: $saveBtnBg;
  color:$saveBtntxt;
  border:$saveBtnBg ;
  &:hover{
    background-color: $saveBtnhoverBg;
    color:$saveBtnhovertxt;
  }
  &:focus{
    background-color: $saveBtnBgFocus !important;
    color:$saveBtnhovertxt !important;
    border-color:transparent  !important;
    box-shadow: 0px 0px 0px -30px rgba(0,0,0,0) !important;

  }
  &:active{
    background-color: $saveBtnActive  !important;
    color:$saveBtnhovertxt  !important;
    border-color:$saveBtnActiveBorder !important;
  }
}

//mandatory border
div .mandatoryClinicalRadio input {
  border: 4px solid var(--mandatoryBorderWarning) !important;
}

div .errorClinicalRadio input {
  border: 4px solid var(--mandatoryBorderError) !important;
}

.mandatoryClinical {
  border: 4px solid var(--mandatoryBorderWarning) !important;
}

.inputMandatory {
  border: 4px solid var(--mandatoryBorderWarning) !important;
}

.errorClinical {
  border: 4px solid var(--mandatoryBorderError) !important;
}

.inputError {
  border: 4px solid var(--mandatoryBorderError) !important;
}


//error text
span.text-danger{
  color:var(--errorTxt) !important;
}
p.text-warning{
  color:var(--mandatoryBorderWarning) !important;
}
p.text-danger{
  color:var(--errorTxt) !important;
}

li.wrongPass{
  color:var(--errorTxt) !important;
}

.modal-footer>p.text-danger{
  color: var(--errorTxt) !important;
}

.saveMsg {
  line-height: 50px;
  }

.logoAppBarLogin{
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

@media (max-width: 800px) {
  .logoAppBarLogin{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 15px;
  }
}

div.react-grid-layout.layout.bg-white{
  background-color: rgba(235,243,247,0.2)!important;
}
div.rolesCont{
    background-color: rgba(0, 0, 0, 0.05)!important;
}
div.rolesCont *{
  color:$appTableClinicalTxtColor;
}
div.react-grid-layout  {
  color:$appTableClinicalTxtColor;
}

div.react-grid-layout>div.react-grid-item>div>div>svg.me-1.dragIcoColor{
  color: var(--lightColor);
}

div.react-grid-item.bgRolesList.react-draggable.cssTransforms.react-resizable-hide{
  background-color: rgba(235,243,247,0.2)!important;
  &:hover{
    background-color: rgba(143, 177, 203, 0.4)!important;
  }
}

//sidebar font weight

div.sidenavOpen.sidebg.sideShadow{
  p{
  }
}

//roles grid border
/*
div>div.rolesCont{
  border:$appTableRoleBorder;
}
div.react-grid-layout{
  border:$appTableRoleBorder;
}*/

//kpi
/*line[Attributes Style] {
  stroke: rgb(102, 102, 102);
  fill: none;
}*/
.recharts-legend-item-text{
  color:var(--heavyColor) !important;
}
.recharts-responsive-container{
  line.recharts-cartesian-axis-line{
    stroke: var(--heavyColor);
  }
  line.recharts-cartesian-axis-tick-line{
    stroke: var(--heavyColor);
  }
  .recharts-text{
    fill:var(--heavyColor);
  }
  circle{
    stroke:var(--mediumColor);
  }
  g.recharts-active-dot{
    circle{
      fill:var(--heavyColor) !important;
    }
  }
  .recharts-tooltip-wrapper *{
    stroke: var(--heavyColor)!important;
    fill: var(--heavyColor)!important;
    color: var(--heavyColor)!important;
  }
  path{
    stroke:var(--lineChart);
  }
}

input[type='file']{
  background-color:$inputFileBg;
  border:2px solid $inputFileBg;
  &:focus{
    background-color:$inputFileBg;
    border:2px solid $inputFileBg;
  }
}

// input type file
#importPatientFileUpload,#importHospitalLogoFileUpload,#importHospitalCoverFileUpload{
  p,label,span{
    text-align: center !important;
    padding-left: 5px !important;
    color: var(--dropZoneP);
  }
  border-radius: 5px;
  padding: 20px;
  background-color: var(--heavyColor);
  border: 2px dashed var(--heavyColorLight);
  cursor: pointer;
  *{
    cursor: pointer;
  }
  &:hover{
    background-color:var(--lightColorLight);
  }
}

.btn-primary, .btn-secondary{
  background-color:$importBtn;
  color: $importBtnTxt;
  border:1px solid $importBtnHover;
  &:hover{
    border:1px solid $importBtnHover;
    background-color:$importBtnHover;
  }
  &:focus{
    border:1px solid $importBtnHover;
    background-color:$importBtnHover;
  }
}

//maintenance

div.reportPadding>p{
  color: $appSubSectionTitleColor;
}

.orangeBorder{
  border: 4px solid var(--mandatoryBorderWarning);
  border-radius: 5px;
}

.redBorder{
  border: 4px solid var(--errorTxt);
  border-radius: 5px;
}


.aLink{
  color:$loginTxtColor;
  &:hover{
    color:var(--mediumColorLight);
  }
}

.spanExportFileSize{
  color:$loginTxtColor;
}

div .bottom-left-info > p, h3, div > p.textPSize {
  color: $headerTitleColor !important;
}

//time range
.dropdownItemCustom{
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdownElem{
  background-color: var(--heavyColorLight);
}


.dropDownToggle{
  min-width: 200px !important;
  color: $dropDownTxt;
  padding-left: 10px;
  padding-right: 10px;
  background: var(--heavyColorLight);
  &:hover{
    background: var(--mediumColor);
  }
  &:focus{
    background: var(--mediumColor);
    border: 1px solid var(--importBtnHover) !important;
    box-shadow:none!important;
  }
  &:active{
    background: var(--mediumColor) !important;
    border: 1px solid var(--importBtnHover) !important;
  }
  border: 1px solid var(--importBtnHover) !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .5em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropDownToggle>.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: var(--heavyColorLight) !important;
}


//verticalDividerTimerange
.verticalDividerTimerange{
  border-left: 1px solid $dropDownTxt;
}

.relativeTimeRangeElem{
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  color: $dropDownTxt;
  &:hover{
    background:rgba(255, 255, 255, 0.1) !important ;
    border-radius: 5px;
  }
}
.relativeTimeRangeElemActive{
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  color: $dropDownTxt;
  background:rgba(255, 255, 255, 0.1) !important ;
  border-radius: 5px;
}

.timeRangeSubTitle{
  color: $dropDownTxt;
}

.labelTimeRange{
  color: $dropDownTxt;
}


.drugSettingTableBorder{
  border-radius: 5px;
  border:1px solid $appTableClinicalBorder !important;
}


.drugSettingTableBorder{
  border-radius: 5px;
  border:1px solid $appTableClinicalBorder !important;
}
//pagination
.page-link{
  color: var(--heavyColorPagi);
  background-color:  $appTableDashboardHeaderBGColor;
  border: 1px solid var(--borderAll);
}

.page-item.active .page-link{
  color: #fff;
  background-color: var(--heavyColor);
  border-color: 1px solid var(--borderAll);
}
.page-link:hover {
  z-index: 2;
  color: var(--heavyColorPagi);
  background-color: var(--lightColorLight);
  border-color:var(--lightColorLight);
}

.page-link:focus {
  box-shadow: none;
}

.searchBar{
  background-color: $heavyColor;
}

.btn-success:hover {
  color: #fff;
  background-color: #159947;
  border-color: #146c43;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ddc107;
  border-color: #ffc107;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.customButton{
  background-color:$importBtnHover;
  color: $importBtnTxt;
  border:1px solid $importBtnHover;
  &:hover{
    border:1px solid $importBtnHover;
    background-color:$importBtn;
  }
}

.button1Color{
  background-color:  var(--btnColor1);
  color: var(--white);
  &:hover{
    background-color: var(--btnColor1Hover);
    color: var(--white);
  }
}

.button2Color{
  background-color: var(--btnColor2);
  color: var(--white);
  &:hover{
    background-color: var(--btnColor2Hover);
    color: var(--white);
  }
}

.button3Color{
  background-color: var(--btnColor3);
  color: var(--white);
  &:hover{
    background-color: var(--btnColor3Hover);
    color: var(--white);
  }
}

.button4Color{
  background-color: var(--btnColor4);
  color: var(--white);
  &:hover{
    background-color: var(--btnColor4Hover);
    color: var(--white);
  }
}

.buttonColorDanger{
  background-color: var(--btnColorDanger);
  color: var(--white);
  border:1px solid var(--btnColorDanger);
  &:hover{
    background-color: var(--btnColorDangerHover);
    border:1px solid var(--btnColorDangerHover);
    color: var(--white);
  }
  &:focus{
    background-color: var(--btnColorDangerHover);
    border:1px solid var(--btnColorDangerHover);
    color: var(--white);
  }
  &:active{
    background-color: var(--btnColorDangerHover);
    border:1px solid var(--btnColorDangerHover);
    color: var(--white);
  }
}


.ck.ck-toolbar{
  background-color: var(--ckToolbarBgvar) !important;
  border: 1px solid var(--ckToolbarBorder) !important;
}

.ck.ck-reset_all, .ck.ck-reset_all *{
  color: var(--ckToolbarColor) !important;
}
.ck.ck-button.ck-on.ck-disabled, a.ck.ck-button.ck-on.ck-disabled{
  background-color: var(--ckToolbarSelected) !important;
}


.letterInModal{
background-color: $letterBg !important;
}

.input-group-text{
  border: none;
  background-color:$advancedSearchBGbuttonGr;
}


.modal-container .modal-content .letterInModal > .row *{
  span.ck.ck-tooltip__text{
    color: white !important;
  }
}

//datepicker background
.react-datepicker {
  background-color:var(--datepickerBG)!important;
}

.react-datepicker__header{
  background-color: var(--datepickerHeaderBG)!important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color:var(--datepickerColor)!important;

}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  color:var(--datepickerColor)!important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
  color:var(--datepickerColorDisable)!important;
}

.react-datepicker__month-select{
  background: $inputBg;
  color:var(--datepickerColor) ;
}
.react-datepicker__year-select{
  background: $inputBg;
  color:var(--datepickerColor) ;
}

.react-datepicker__year .react-datepicker__year-text{
  color:var(--datepickerColor) ;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
  color:var(--datepickerColor) ;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  border-bottom-color:var(--datepickerHeaderBG) !important;
}

.boneSelectLabel{
  /*background-color:var(--boneBread);*/
  background-color:var(--multiValueBG) !important;
  color: var(--inputValue) !important;
}

.hideSeparator{
  [class$="-indicatorSeparator"] {
    display: none !important;
  }
}

svg{
  text{
    stroke: var(--appSectionTitleColor);
    fill:var(--appSectionTitleColor) ;
  }
}

h3{
color:var(--appSectionTitleColor)!important;
}

.genericHover{
  &:hover{
    background-color: rgba(0,0,0,0.1);
  }

}


//todo change this background with variable when merge with invisible branch !!!
.editionModeElem{
  background-color: var(--appBackgroundColor);
}

.editionModeElem input  {
  background-color: var(--hiddenEditionInput) !important;
}

.editionModeElem textarea  {
  background-color: var(--hiddenEditionInput) ;
}
.editionModeElemHidden  {
  background-color: var(--hiddenEdition) ;
}

.editionModeElem:hover{
  background-color: var(--hiddenEdition) ;
}

.editionModeElem [class$="-control"]{
  background-color: var(--hiddenEditionInput) !important; ;
}

.editionModeElemCard{
  background-color: var(--appBackgroundColor);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: rgba(195,195,195,0.5);
  border-color: rgba(195,195,195,0.5);
}

a.dropdown-item {
  color: var(--dropDownTxt);
  text-decoration: none;
}

.whiteSpinner{
  color: white !important;
}

a{
color:var(--appSectionTitleColor);
}

.boneSelectIco > svg > path{
  stroke: var(--inputValue) !important;
}

.margin-right0{
  margin-right: 0px !important;
}
.margin-left0{
  margin-left: 0px !important;
}

.widgetContainer{
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.widget{
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  background-color: var(--multiValueBG2) !important;
  color: var(--multiValueColor);
}
.widgetLabel{
  border-radius: 2px;
  padding-left: 3px;
  white-space: nowrap;
  box-sizing: border-box;
  color: var(--inputValue) !important;
  padding-right: 3px;
  text-transform: uppercase;
}

.inlineRemoveButton{
  webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 2px;
  &:hover{
    background-color: #FFBDAD;
    color: #DE350B !important;
    path{
      stroke: #DE350B !important;
      fill: #DE350B !important;
    }
  }
}
.svgInlineRemoveButton{
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}