/** {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.35px;
}*/
.radial-violet--before::before {
  content: "";
  display: block;
  height: 1000px;
  width: 80%;
  max-width: 650px;
  position: absolute;
  background-image: radial-gradient(circle at center left, rgba(123, 86, 159, 0.45), rgba(123, 86, 159, 0) 60%); }

.navBarLinkDecoration{
  text-decoration:none;
  color:black;
}

.hamburgerMenu{
  color:#7B569F;
  font-size:24px;
}

.closeCross{
  color: white;
}

.shadowed, .quotes {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.05); }

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.gradient-before::before {
  content: '';
  display: block;
  height: 500px;
  width: 500px;
  position: absolute;
  background-image: radial-gradient(circle at top right, rgba(123, 86, 159, 0.3), rgba(123, 86, 159, 0) 70%);
  right: 0;
  top: 0;
  z-index: 2; }

.blue {
  color: #343D4A; }
  .blue--bg {
    color: #FFF;
    background-color: #343D4A; }

.grey--bg {
  background-color: #F4F4F4; }

.gradient--bg {
  background: #7b569f;
  background: linear-gradient(120deg, rgba(123, 86, 159, 0) 0%, rgba(123, 86, 159, 0.25) 20%, rgba(34, 39, 50, 0.6) 30%, #222732 70%, #222732 100%); }

.gradient-violet--bg {
  background-image: radial-gradient(circle at top left, #ae95c6, #765298 50%); }

.white {
  color: #FFF; }
  .white span,
  .white small,
  .white p, .white strong {
    color: #FFF; }
  .white--bg {
    background-color: #FFF; }

/** {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.35px; }*/

a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  text-decoration: none; }
  a:hover {
    text-decoration: none !important;
    color: inherit; }

strong {
  font-weight: 700; }

p + a,
p + ul,
ul + a {
  margin-top: 30px; }

.btnS, .btn-primary, .btn-secondary, .btn-link, form input {
  /*padding: 10px 20px;*/
  color: #343D4A; }
  .btn:hover, .btn-primary:hover, .btn-secondary:hover, .btn-link:hover, form input:hover {
    color: inherit; }

.btn-primary, .btn-secondary {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  color: #FFF;
  text-transform: uppercase;
  background-color: #7B569F;
  border-radius: 3px;
  cursor: pointer;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .btn-primary i, .btn-secondary i {
    color: #FFF;
    margin: 0;
    padding: 0 10px 0 0;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  .btn-primary:hover, .btn-secondary:hover, .btn-primary:active, .btn-secondary:active, .btn-primary.active, .active.btn-secondary, .btn-primary:focus, .btn-secondary:focus {
    color: #FFF;
    background-color: #6e4d8e; }
    .btn-primary:hover i, .btn-secondary:hover i, .btn-primary:active i, .btn-secondary:active i, .btn-primary.active i, .active.btn-secondary i, .btn-primary:focus i, .btn-secondary:focus i {
      padding: 0 5px; }

.btn-secondary {
  background-color: #48325d; }
  .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
    background-color: #2e203c; }

.btn-menu {
  position: absolute;
  line-height: 75px;
  right: 10px;
  top: 0px;
  height: 75px;
  width: 50px;
  text-align: center; }
  .btn-menu div {
    color: #7B569F;
    font-size: 20px; }
  .btn-menu.close-menu {
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px; }
    .btn-menu.close-menu div {
      font-size: 16px;
      color: #FFF; }
    .btn-menu:hover{
      cursor: pointer;
    }
.btn-small {
  font-size: 14px;
  padding: 5px;
  color: #FFF;
  opacity: 0.5; }

.btn-link {
  padding: 5px 5px; }
  .btn-link:hover {
    color: #7B569F; }

.btn-link.white {
  color: #FFF;
  opacity: 0.7;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .btn-link.white:hover {
    opacity: 1; }
  .btn-link.white i {
    color: #FFF;
    margin: 0;
    padding: 0 5px;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }

.btn-icon i {
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.btn-icon:hover i {
  color: #FFF; }

.max-width-sm {
  width: 100%;
  max-width: 400px; }

.max-width-md, .quotes .carousel {
  width: 100%;
  max-width: 540px; }

.max-width-lg {
  width: 100%;
  max-width: 690px; }

.max-width-xl {
  width: 100%;
  max-width: 840px; }

h1, .h1 {
  font-size: 44px;
  line-height: 1em;
  font-family: "Roboto Slab", serif;
  font-weight: 400; }

h2, .h2 {
  font-size: 36px;
  line-height: 1em;
  font-family: "Roboto Slab", serif;
  font-weight: 400; }

h3, .h3, section.privacy h2 {
  font-size: 30px; }

h4, .h4, section.privacy h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-size: 24px; }

.condensed {
  font-family: "Roboto Condensed", sans-serif; }

p {
  font-size: 18px;
  line-height: 1.6em; }

small {
  font-size: 0.6em; }

.title {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  padding: 5px 0 30px; }
  .title span {
    font-family: "Roboto Slab", serif;
    font-weight: 400;
    display: block; }
  .title small {
    font-family: "Roboto Slab", serif;
    font-weight: 400;
    font-weight: 300;
    display: block;
    opacity: 0.6; }

.big-title {
  font-size: 53px; }

.custom-list {
  padding: 0; }
  .custom-list li {
    list-style: none;
    position: relative;
    padding: 0 0 5px 30px; }
    .custom-list li::before {
      position: absolute;
      display: block;
      content: "\f00c";
      font-family: FontAwesome;
      left: 0;
      color: #7B569F; }

.gradient-text {
  background: -webkit-linear-gradient(60deg, #61447e, #ae95c6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.motif--bg {
  background-repeat: no-repeat;
  background-position: right -200px top -100px;
  background-size: 500px auto;
  background-image: url("../images/bone-monitor-opacity.svg"); }

body {
  background-color: #F4F4F4;
  min-height: 100vh;
   }
  body.active {
    overflow: hidden; }

header {
  background-color: #FFF; }

nav {
  padding: 10px 0 15px; }
  nav .logo-wrapper img {
    max-height: 80px; }
  nav.main-nav ul.menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right; }
    nav.main-nav ul.menu-list li {
      display: inline-block; }
      nav.main-nav ul.menu-list li a {
        display: block;
        position: relative;
        margin: 0 25px;
        -moz-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
        nav.main-nav ul.menu-list li a::before {
          position: absolute;
          content: '';
          display: block;
          height: 2px;
          right: 0;
          left: 0;
          top: 0;
          background-color: #343D4A;
          -moz-transition: all 0.5s;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          right: 100%;
          height: 2px; }
        nav.main-nav ul.menu-list li a.active::before {
          right: 0; }
        nav.main-nav ul.menu-list li a:hover {
          color: #7B569F; }
          nav.main-nav ul.menu-list li a:hover::before {
            right: 0;
            background-color: #7B569F; }
  nav.mobile-nav {
    height: auto;
    position: fixed;
    z-index: 9999;
    bottom: -10px;
    left: 10px;
    right: 10px;
    padding: 0;
    min-height: 40vh;
    background-color: #222732;
    border-radius: 3px;
    overflow: hidden;
    transition: bottom 1s; }
    nav.mobile-nav.active {
      bottom: 10px; }
    nav.mobile-nav .nav-wrapper {
      position: relative;
      z-index: 2;
      padding: 40px 15px 20px; }
    nav.mobile-nav .title {
      padding: 0 0 20px;
      font-size: 18px; }
    nav.mobile-nav ul {
      padding: 0;
      text-align: center;
      margin-bottom: 0; }
      nav.mobile-nav ul li {
        display: block;
        margin: 0 auto 5px; }
        nav.mobile-nav ul li a {
          color: #FFF; }
          nav.mobile-nav ul li a.btn-link-mobile {
            padding: 5px 10px;
            font-size: 18px;
            display: block;
            font-size: 24px; }
          nav.mobile-nav ul li a.active {
            color: #7B569F; }
          nav.mobile-nav ul li a.btn-primary, nav.mobile-nav ul li a.btn-secondary {
            font-size: 16px; }

section.generic-banner {
  padding-top: 80px;
  padding-bottom: 80px; }

section.home {
  position: relative;
  min-height: 80vh;
  background-color: #343D4A;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url("../images/bone-monitor-image.jpg"); }
section.homeBasic {
  background-color: #343D4A;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
   }
section.homeBasic .gradient-overlay {
  background: #7b569f;
  background: linear-gradient(120deg, rgba(123, 86, 159, 0) 0%, rgba(123, 86, 159, 0.25) 20%, rgba(34, 39, 50, 0.6) 30%, #222732 70%, #222732 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute; }
section.homeBasic .container .content {
  margin: 0 0 0 auto; }
  section.home .gradient-overlay {
    background: #7b569f;
    background: linear-gradient(120deg, rgba(123, 86, 159, 0) 0%, rgba(123, 86, 159, 0.25) 20%, rgba(34, 39, 50, 0.6) 30%, #222732 70%, #222732 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute; }
  section.home .container .content {
    margin: 0 0 0 auto; }

section .content-wrapper {
  position: relative;
  z-index: 2; }

section.fls-intro {
  background-image: url("../images/bone-monitor-outline-violet.svg");
  background-repeat: no-repeat;
  background-position: right -200px top;
  background-size: 600px auto; }

section.contact {
  background-size: auto 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: url("../images/bone-monitor-medecin.jpg"); }
  section.contact .content-wrapper {
    padding: 80px;
    margin: 0 0 0 auto; }
    section.contact .content-wrapper .title small {
      opacity: 1; }

/*.connection-modal {
  height: auto;
  width: 100%;
  bottom: 20px;
  padding: 30px 0 0;
  border-radius: 3px; }
  .connection-modal .logo {
    display: block;
    max-width: 200px;
    width: 100%;
    margin: 30px auto; }*/

form label.btn-submit {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 300px; }
  form label.btn-submit .btn-primary, form label.btn-submit .btn-secondary {
    text-align: center;
    display: block; }
  form label.btn-submit input {
    display: none; }

form input {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  width: 100%;
  max-width: 500px;
  background-color: #222732;
  border: 2px solid #343D4A;
  border-radius: 3px;
  color: #FFF;
  cursor: pointer;
  margin: 0 auto 5px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  form input:focus {
    color: inherit !important; /*#FFF*/
    background-color: #343D4A; }
  form input:hover {
    color: #FFF; }

form .btn-small {
  display: block;
  text-align: center;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  form .btn-small:hover {
    color: #FFF;
    opacity: 1; }

.two-col-content .image-wrapper img {
  border-radius: 3px; }

.two-col-content .text-wrapper {
  padding-left: 45px;
  padding-bottom: 30px; }

@media screen and (min-width: 1024px) {
  .sticky-topStyle {
    position: sticky;
    position: -moz-sticky;
    position: -webkit-sticky;
    top: 80px; } }

.schema-wrapper {
  position: relative;
  padding-top: 40px;
  padding-bottom: 80px; }
  .schema-wrapper strong {
    display: block;
    text-align: center;
    width: 100%; }
    .schema-wrapper strong span {
      display: none; }
    .schema-wrapper strong.left-text {
      position: absolute;
      left: 0;
      top: 46%;
      width: 25%;
      max-width: 300px;
      text-align: right; }
    .schema-wrapper strong.right-text {
      position: absolute;
      right: 0;
      top: 46%;
      width: 25%;
      max-width: 300px;
      text-align: left; }
  .schema-wrapper img {
    display: block;
    width: 50%;
    max-width: 400px;
    margin: 20px auto; }

.listing-usp {
  padding-left: 45px; }

.quotes-content {
  margin-bottom: 80px; }

.quotes {
  padding: 0;
  border-radius: 3px; }
  .quotes .content {
    padding: 80px 15px 80px; }
  .quotes .text-wrapper {
    text-align: center;
    padding: 30px 0 15px; }
    .quotes .text-wrapper a {
      margin-top: 20px; }
  .quotes .carousel {
    width: 100%;
    margin: 0 auto; }
  .quotes .flickity-page-dots {
    position: static; }
    .quotes .flickity-page-dots .dot {
      background: #FFF; }
  .quotes .quotes-item {
    width: 100%;
    height: auto;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 3px;
    text-align: center;
    /*background-color: darken($violet, 20); */ }
    .quotes .quotes-item h3 {
      text-transform: uppercase;
      padding: 30px 20px 10px; }
      .quotes .quotes-item h3 i {
        color: #FFF;
        font-weight: 400; }
      .quotes .quotes-item h3::before, .quotes .quotes-item h3::after {
        content: '\f100';
        display: inline-block;
        color: #48325d;
        font-family: FontAwesome;
        margin: 0 10px 0 0; }
      .quotes .quotes-item h3::after {
        margin: 0 0 0 10px;
        content: '\f101'; }

.contact-list {
  padding: 0;
  margin: 40px 0 0; }
  .contact-list li {
    list-style: none;
    position: relative;
    padding-left: 40px;
    margin: 0 0 10px;
    min-height: 45px;
    font-size: 16px; }
    .contact-list li span {
      left: 0;
      width: 35px;
      text-align: center;
      position: absolute; }
      .contact-list li span i {
        font-size: 24px;
        color: #7B569F; }
    .contact-list li a {
      line-height: 45px;
      font-family: "Roboto", sans-serif;
      font-weight: 300; }

footer .main-footer {
  padding: 80px 0 40px;
  margin-bottom: 40px;
  border-bottom: 2px solid #6e4d8e; }
  footer .main-footer .logo-wrapper .logo {
    width: 220px; }
  footer .main-footer .info-wrapper {
    padding-right: 30px; }
    footer .main-footer .info-wrapper p {
      margin: 30px 0 0;
      color: rgba(255, 255, 255, 0.7);
      opacity: 0.7; }
  footer .main-footer .contact-wrapper .contact-list {
    margin: 30px 0 0; }
    footer .main-footer .contact-wrapper .contact-list li {
      color: rgba(255, 255, 255, 0.7); }
      footer .main-footer .contact-wrapper .contact-list li span i {
        color: rgba(255, 255, 255, 0.7); }
      footer .main-footer .contact-wrapper .contact-list li a {
        color: rgba(255, 255, 255, 0.7); }

footer .legal-footer {
  padding-bottom: 26.66667px; }

footer .legal-wrapper {
  text-align: left; }
  footer .legal-wrapper span {
    opacity: 0.7; }

footer .copy-wrapper {
  text-align: right; }
  footer .copy-wrapper span {
    opacity: 0.7; }

/* Privacy and legal pages */
section.privacy--head .title {
  padding-bottom: 10px; }

section.privacy {
  margin: 0 auto 80px; }
  section.privacy h3 {
    font-family: "Roboto Slab", serif;
    font-weight: 400; }
  section.privacy .text-wrapper {
    padding: 40px; }
  section.privacy p + h2,
  section.privacy p + h3 {
    padding-top: 30px; }
  section.privacy ul {
    padding-left: 20px; }
    section.privacy ul li {
      list-style: circle; }
  section.privacy a {
    color: #7B569F; }
    section.privacy a:hover {
      color: #7B569F; }

#cookit.hidden {
  display: none; }

#cookit {
  z-index: 999;
  position: fixed;
  background-color: #222732;
  left: 5px;
  right: 5px;
  bottom: 5px;
  width: auto;
  padding: 20px;
  border-radius: 3px;
  text-align: center; }
  #cookit #cookit-message {
    display: block;
    color: #FFF;
    margin: 0;
    font-size: 16px;
    padding: 0 5px 5px; }
  #cookit #cookit-container a {
    color: #7B569F;
    margin: 0;
    display: inline-block;
    font-size: 16px;
    margin-right: 15px; }
    #cookit #cookit-container a#cookit-button {
      padding: 5px 10px;
      margin-left: auto;
      border-radius: 3px;
      background-color: #FFF;
      color: #7B569F;
      font-weight: 400;
      margin-left: auto;
      text-transform: uppercase; }

@media screen and (min-width: 1600px) {
  section.home {
    background-size: cover; } }

@media screen and (min-width: 2400px) {
  section.home {
    background-size: cover;
    min-height: 60vh; }
  section.generic-banner {
    padding-top: 180px;
    padding-bottom: 180px; }
  .max-width-md, .quotes .carousel {
    max-width: 640px; } }

@media screen and (max-width: 1200px) {
  footer .logo-wrapper {
    padding-bottom: 40px; } }

@media screen and (max-width: 1024px) {
  p {
    font-size: 16px; }
  h1, .h1 {
    font-size: 40px; }
  h2, .h2 {
    font-size: 36px; }
  h3, .h3, section.privacy h2 {
    font-size: 30px; }
  h4, .h4, section.privacy h3 {
    font-size: 26px; }
  .big-title {
    font-size: 44px; }
  .max-width-sm {
    max-width: 570px; }
  .max-width-md, .quotes .carousel {
    max-width: 640px; }
  .max-width-lg {
    max-width: 740px; }
  .max-width-xl {
    max-width: 840px; }
  footer .main-footer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    max-width: 840px; }
    footer .main-footer .contact-wrapper {
      padding-top: 40px; }
  footer .legal-footer {
    margin-left: auto;
    margin-right: auto;
    max-width: 840px; }
  footer .logo-wrapper {
    padding-bottom: 40px; }
  section.home {
    min-height: 200px;
    background-size: auto 100%; }
  .connection-modal .logo {
    margin: 0; }
  form a.btn-small {
    max-width: 300px; }
  form input {
    margin: 0 0 5px; }
  form label.btn-submit {
    margin: 0; }
  section.fls-intro {
    background-size: 440px auto; }
  section.contact .container-fluid {
    padding: 0; } }

@media screen and (max-width: 768px) {
  h3, .h3, section.privacy h2 {
    font-size: 30px; }
  h4, .h4, section.privacy h3 {
    font-size: 22px; }
  .sticky-topStyle {
    position: static; }
  .max-width-lg {
    max-width: 640px; }
  nav ul.menu-list li a {
    margin: 0 15px; }
  .quotes,
  .two-col-content,
  footer .main-footer,
  footer .legal-footer {
    max-width: 640px; }
  .gradient-before::before {
    width: 100%;
    height: 100%;
    /*background: linear-gradient(0deg, #222732 0%, rgba(34, 39, 50, 0.7) 50%, rgba(123, 86, 159, 0.5) 100%);*/ }
  section.home {
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 120% auto; }
    section.home .container .content {
      padding: 0 15px;
      margin: 0 auto; }
  section.fls-intro {
    padding-top: 200px;
    margin-top: -100px;
    position: relative;
    z-index: 1; }
  section.contact .content-wrapper {
    max-width: 540px; }
  #fracture .container {
    position: relative; }
  .two-col-content {
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    .two-col-content .text-wrapper {
      padding: 40px;
      margin: 240px auto 0;
      background-color: #FFF;
      border-radius: 3px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
    .two-col-content .image-wrapper {
      position: absolute;
      padding: 0; }
      .two-col-content .image-wrapper .image-inner-wrapper {
        border-radius: 3px;
        height: 270px;
        width: 100%;
        margin: 0 auto 30px;
        overflow: hidden; }
      .two-col-content .image-wrapper img {
        width: 100%; }
  .schema-wrapper {
    padding-top: 0; }
    .schema-wrapper img {
      width: 40%; }
    .schema-wrapper strong.right-text,
    .schema-wrapper strong.left-text {
      width: 25%; }
  .quotes .content {
    padding: 40px; }
  footer .main-footer .contact-wrapper {
    padding-top: 40px; } }

@media screen and (max-width: 599px) {
  h1, .h1 {
    font-size: 34px; }
  h2, .h2 {
    font-size: 30px; }
  h3, .h3, section.privacy h2 {
    font-size: 27px; }
  h4, .h4, section.privacy h3 {
    font-size: 24px; }
  .big-title {
    font-size: 38px; }
  p + a,
  p + ul,
  ul + a {
    margin-top: 20px; }
  header {
    padding: 10px;
    position: fixed;
    width: 100%;
    height: 75px !important;
    z-index: 10;
    background: none; }
    header .container {
      padding: 0; }
  section.generic-banner.home, section.generic-banner.two-col-content {
    padding-top: 120px; }
  section.contact {
    padding-top: 100px; }
  nav .menu-wrapper {
    display: none; }
  nav .logo-wrapper {
    margin: 0 auto;
    text-align: center; }
  nav.main-nav {
    width: 100%;
    margin: 0 auto;
    background-color: #FFF;
    border-radius: 3px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
    nav.main-nav .logo-wrapper img {
      max-height: 50px; }
  .mobile-only {
    display: block; }
  .gradient-before::before {
    /*background: linear-gradient(0deg, #222732 0%, #222732 50%, rgba(123, 86, 159, 0.2) 100%);*/ }
  section.home {
    background-size: 650px auto; }
  section.generic-banner {
    padding-top: 40px;
    padding-bottom: 40px; }
  section.contact {
    background: none; }
    section.contact .content-wrapper {
      padding: 40px 30px;
      margin: 0 auto; }
    section.contact .title-wrapper {
      position: relative;
      padding-left: 80px; }
      section.contact .title-wrapper .title {
        display: block; }
    section.contact .thumbnail {
      border-radius: 100px;
      height: 80px;
      width: 80px;
      overflow: hidden;
      position: absolute;
      left: -5px;
      top: 5px; }
      section.contact .thumbnail img {
        width: auto;
        max-width: 100%; }
  .connection-modal {
    margin: 0 auto;
    padding-top: 80px; }
    .connection-modal .logo {
      margin: 0 auto 30px; }
  form input {
    width: 100%;
    margin:  auto;
    max-width: 350px; }
  form label.btn-submit {
    margin: 0 auto;
    max-width: 350px; }
  form a.btn-small {
    margin: 10px auto 0; }
  .two-col-content .container .row {
    padding: 0 15px; }
  .two-col-content .image-wrapper {
    position: static; }
    .two-col-content .image-wrapper .image-inner-wrapper {
      height: 170px;
      margin: 0 auto;
      border-radius: 3px 3px 0 0; }
  .two-col-content .text-wrapper {
    margin: 0 auto; }
  .quotes {
    max-width: 510px; }
    .quotes .text-wrapper {
      padding: 15px 0 0; }
  .radial-violet--before::before {
    width: 100%; }
  .schema-wrapper {
    padding-top: 160px; }
    .schema-wrapper img {
      width: 90%;
      max-width: 340px; }
    .schema-wrapper strong {
      font-size: 22px;
      padding: 0 15px 15px; }
      .schema-wrapper strong span {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto 10px;
        line-height: 40px;
        font-weight: bold;
        text-align: center;
        color: #FFF;
        background-color: #7B569F;
        font-family: 'Roboto', sans-serif;
        border-radius: 50px; }
      .schema-wrapper strong.right-text,
      .schema-wrapper strong.left-text {
        width: 100%;
        max-width: 100%;
        position: static;
        text-align: center; }
  .listing-usp {
    padding-left: 15px; }
  .contact-list li {
    margin: 0; }
    .contact-list li a {
      display: block; }
  .legal-wrapper .btn-link.white {
    padding: 0; } }

@media screen and (max-width: 480px) {
  .big-title {
    font-size: 34px; }
  section.two-col-content {
    padding-top: 20px; }
    section.two-col-content .text-wrapper {
      padding: 30px 20px; }
  section.fls-intro .content {
    padding: 0 15px; }
    section.fls-intro .content .title span {
      display: inline; }
  section.contact .thumbnail {
    width: 70px;
    height: 70px;
    margin: 0 10px 30px 0; }
  .schema-wrapper {
    padding-bottom: 40px; }
  .quotes-content {
    padding: 0 15px; }
  .quotes .content {
    padding: 30px; }
  footer .main-footer .info-wrapper {
    padding: 0 15px; }
  footer .logo-wrapper {
    text-align: center; }
    footer .logo-wrapper .logo {
      max-width: 160px; }
  footer .legal-wrapper,
  footer .copy-wrapper {
    text-align: center; } }

