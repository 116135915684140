.boneSelectorContainer{
  display: flex;
  justify-content:space-evenly;
  flex-wrap:wrap;
}

.boneSelectorParent {
  position: relative;
  top: 0;
  left: 0;
  min-width: 250px;
  margin: auto;
  z-index: 100;
  cursor: pointer;
}

.boneSelectorCheck{
  min-width: 250px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.seenImg {
  position: relative;
  top: 0;
  left: 0;
}
.selectedImg {
  position: absolute;
  top: 0;
  left: 0;
}

.boneSelectLabel{
  margin: 2px 0px 0px 2px;
  padding-left: 2px;
  padding-right: 0px;
  margin-right: 0px;
  border-radius: 5px;
  text-align: left;
  display:block ruby;
}
.boneSelectIco{
  background-color:var(--multiValueBG) !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  /*background-color: var(--multiValueBG) !important;*/
  color: var(--multiValueColor)!important;
  &:hover{
    background-color: #FFBDAD!important;
    color: #DE350B!important;
  }
}


.boneSelectIco svg{
  vertical-align: middle;
  margin-bottom: 0px;
  color: var(--inputValue) !important;
  &:hover{
    background-color: #FFBDAD!important;
    path{
      color: #DE350B!important;
      stroke: #DE350B!important;
    }
  }
}

.boneCont{
  margin: 1px;
}

.boneListCont{
  display: flex;
  max-height: 650px;
  flex-wrap: wrap;
  //min-width: 100px;
  flex-direction: column;
}

.boneListFlexCont{
  max-height: 650px;
  display: flex;
  justify-content: space-evenly;
}

.noSelectedBone{
  visibility: hidden;
}

.boneOverviewPosition{
  margin-bottom: 10px;
}

.spineOverviewPosition{
  margin-bottom: 10px;
}

.previewBonePosition{
  position: absolute;
  left: 56.0%;
  top: -195px;
  min-width: 465px;
  z-index: 100;
}

@media screen and (max-width: 1340px) {
  .previewBonePosition{
    display: none;
  }
}
.halfScale{
  transform: scale(0.5);
}

.scale{
  transform: scale(1.5) !important;
}

.boneSelectorParentPrev {
  position: relative;
  top: 0;
  left: 0;
  margin: auto;
  z-index: 100;
}

.displayBoneBtn{
  display: none !important;
}

@media screen and (max-width: 1340px) {
  .displayBoneBtn{
    display: block !important;
  }
}



